import { CHAIN_INFO } from '@/constants/chains.constant';
import { SAVED_CONNECTOR_KEY } from '@/constants/common.constant';

class MetaMaskConnector {
  constructor() {}

  getProvider() {
    return window?.ethereum;
  }

  //environmental discrimination
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  setup() {}

  subscribeToEvents(chainChangeCb, accountChangeCb, disconnectCb) {
    const provider = this.getProvider();
    if (!provider) return;
    if (provider?.removeAllListeners) {
      provider.removeAllListeners();
    }

    provider.on('accountsChanged', (accounts) => {
      if (accounts.length) {
        if (accountChangeCb) {
          accountChangeCb(accounts[0]);
        }
      } else {
        if (disconnectCb) {
          console.log('no account availabe, disconnecting...');
          disconnectCb();
        }
      }
    });

    provider.on('chainChanged', (networkId) => {
      const chainId = Number(networkId);
      if (!chainId || isNaN(chainId)) {
        if (disconnectCb) {
          disconnectCb();
        }
      } else {
        if (chainChangeCb) {
          chainChangeCb(chainId);
        }
      }
    });
  }

  addChain({ explorer, name, nativeCurrency, rpcUrl, chain }) {
    const provider = this.getProvider();
    provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: this.toHex(chain),
          chainName: name,
          nativeCurrency: {
            ...nativeCurrency,
          },
          rpcUrls: [rpcUrl],
          blockExplorerUrls: [explorer],
        },
      ],
    });
  }

  async _switchChain({ explorer, name, nativeCurrency, rpcUrl, chain }) {
    try {
      const provider = this.getProvider();
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.toHex(chain) }],
      });
    } catch (error) {
      console.error('in switch chain', error);
      if (error.code === 4902) {
        this.addChain({
          explorer,
          name,
          nativeCurrency,
          rpcUrl,
          chain,
        });
      } else {
        throw error;
      }
    }
  }

  async switchChain(chain) {
    try {
      const provider = this.getProvider();
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.toHex(chain) }],
      });
    } catch (error) {
      console.error('in switch chain', error);
      if (error.code === 4902) {
        this.addChain({
          ...CHAIN_INFO[chain],
          chain,
        });
      } else {
        throw error;
      }
    }
  }

  toHex(chainIdDec) {
    const chainInHex = `0x${Number(chainIdDec).toString(16)}`;
    return chainInHex;
  }

  async connect(chain) {
    const chainInfo = CHAIN_INFO[chain];
    const provider = this.getProvider();

    if (!provider) {
      if (this.isMobile()) {
        const mmAppDeepLink = `https://metamask.app.link/dapp/${window.location.hostname}`;
        window.location.href = mmAppDeepLink;

        // Wait for application transition
        return new Promise((resolve) => {
          const checkConnection = setInterval(async () => {
            if (window.ethereum) {
              clearInterval(checkConnection);
              try {
                await this.connectAfterAppOpen(chain, chainInfo);
                resolve(true);
              } catch (error) {
                console.error('MetaMask接続エラー:', error);
                resolve(false);
              }
            }
          }, 1000); // Check every second
        });
      } else {
        throw new Error('Please install Metamask!');
      }
    }

    const isMetaMask = await this.isMetaMaskProvider();
    if (!isMetaMask) {
      console.warn('MetaMask is not the primary provider. Attempting to connect anyway.');
    }

    // browser extension
    return this.connectAfterAppOpen(chain, chainInfo);
  }

  async connectAfterAppOpen(chain, chainInfo) {
    const provider = this.getProvider();
    if (!provider) {
      throw new Error('MetaMask provider not found');
    }

    try {
      const chainId = Number(await provider.request({ method: 'eth_chainId' }));
      if (chainId !== chain) {
        await this._switchChain({ ...chainInfo, chain });
      }
      await provider.request({ method: 'eth_requestAccounts' });
      return true;
    } catch (error) {
      console.error('MetaMask接続エラー:', error);
      throw error;
    }
  }

  async disconnect() {
    const provider = this.getProvider();
    if (provider) {
      if (provider.removeAllListeners) {
        provider.removeAllListeners();
      }
      if (provider.close) {
        provider.close();
      }
      if (provider.disconnect) {
        provider.disconnect();
      }
    }
    window.localStorage.removeItem(SAVED_CONNECTOR_KEY);
  }
}
const MetaMaskPlugin = {
  install(Vue) {
    const connector = new MetaMaskConnector();
    Vue.prototype.$metamaskConnector = connector;
  },
};
export default MetaMaskPlugin;
