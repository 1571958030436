<template>
  <a-spin :spinning="loading">
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ パンくずリスト ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="ml-2 sm:ml-16 mb-8"> <!-- 画面幅 640px を境にインデント値を変更 -->
      <BreadcrumbList class="mt-12 mb-0"></BreadcrumbList>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ パンくずリスト ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +=========+=========+=========+=========+=========+=========+====== -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 商品名。画面幅 640px 未満の時のみ表示。↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="title text-black ml-2 mb-2 text-3xl font-semibold visible sm:hidden">
      {{ showName }}
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 商品名。画面幅 640px 未満の時のみ表示。↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- +=========+=========+=========+=========+=========+=========+====== -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 画面幅 640px 以上の場合、2:3 の二列にしている。 ↓ -->
    <!-- ↓ 画面幅 640px 未満の場合、一列。                 ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="sm:grid grid-cols-5 gap-x-10 text-black">
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、左列（2:3の2）。                  ↓ -->
      <!-- ↓ 画面幅 640px 未満の場合、画面横一杯。                      ↓ -->
      <!-- ↓ サムネイル画像、Websiteアイコンとエックスアイコン、        ↓ -->
      <!-- ↓ 画面幅 640px 以上で、チェーン・規格・コントラクトアドレス。↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="col-span-5 sm:col-span-2">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ サムネイル画像 ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="mx-2 sm:mx-16 z-30 relative">
          <img
            class="w-full h-full object-scale-down"
            :src="detail.thumbnail_url"
          />
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ サムネイル画像 ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ Websiteアイコンとエックスアイコンの組をセンタリング ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="flex mx-auto my-6">
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ Websiteアイコンとエックスアイコンの組 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="flex mx-auto items-end">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ Websiteアイコン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a
              v-if="detail.website_url"
              :href="
                detail.website_url.startsWith('http') || detail.website_url.startsWith('www.')
                  ? detail.website_url
                  : `//${detail.website_url}`
              "
              target="_blank"
            >
              <GlobalIcon class="w-7 h-7 text-primary"></GlobalIcon>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ Websiteアイコン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ エックスアイコン ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <a
              v-if="detail.twitter_url"
              :href="
                detail.twitter_url.startsWith('http') || detail.twitter_url.startsWith('www.')
                  ? detail.twitter_url
                  : `//${detail.twitter_url}`
              "
              target="_blank"
            >
              <!--<img src="https://api.zaif-ino.com/static/X_black_icon.png" class="h-7">-->
              <!--<TwitterIcon class="w-7 h-7 text-primary"></TwitterIcon>-->
              <XIcon class="w-full h-full -mt-1 text-primary"></XIcon>
            </a>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ エックスアイコン ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ Websiteアイコンとエックスアイコンの組 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ Websiteアイコンとエックスアイコンの組をセンタリング ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ チェーン・規格・コントラクトアドレス。↓ -->
        <!-- ↓ 画像幅 640px 以上時のみ表示。         ↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="hidden sm:block w-4/5 mx-auto pl-1 border border-gray-400">
          <div class="flex items-center">
            <div class="font-bold">チェーン</div> &emsp; 
            {{ getChainName(detail.chain_id) }}
          </div>
          <div class="flex items-center">
            <div class="font-bold">企画</div> &emsp; 
            ECR-{{ detail.contract_type }}
          </div>
          <div class="flex items-center">
            <div class="font-bold">コントラクトアドレス</div> &ensp;
            <div class="text-xs">{{ detail.contract_address }}</div>
          </div>
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ チェーン・規格・コントラクトアドレス。↑ -->
        <!-- ↑ 画像幅 640px 以上時のみ表示。         ↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、左列（2:3の2）。                  ↑ -->
      <!-- ↑ 画面幅 640px 未満の場合、画面横一杯。                      ↑ -->
      <!-- ↑ サムネイル画像、Websiteアイコンとエックスアイコン、        ↑ -->
      <!-- ↑ 画面幅 640px 以上で、チェーン・規格・コントラクトアドレス。↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+=========+=========+=========+=========+=========+====== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 画面幅 640px 以上の場合、右列（2:3の3）。↓ -->
      <!-- ↓ 画面幅 640px 未満の場合、画面幅一杯。    ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="mx-2 sm:ml-0 sm:mr-16 col-span-5 sm:col-span-3">
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 商品名。横幅 640px 以上の画面のみ表示。↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div class="mb-2 text-3xl font-semibold hidden sm:block mx-auto">
          {{ showName }}
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 商品名。横幅 640px 以上の画面のみ表示。↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ======+=========+=========+=========+=========+=========+====== -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↓ 購入フォーム。データが有るときのみ表示。↓ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <div
          v-if="Object.values(detail).length > 0"
          class="pb-8 mx-auto rounded-t-xl"
        >
          <div class="relative">
            <div v-if="isMaintaining">
              <div>ご案内</div>
              2023年7月7日(金)19:00から、Zaif
              INOのサービスサイトにて、購入ができないエラーが発生しております。
              <br />
              多くのお客さまに
              <br />
              ご迷惑をおかけ致しまして誠に申し訳ございませんでした。
              <br />
              現在システムチームとエラーの原因を調査中です。
              <br />
              無事復旧いたしましたら、改めてお知らせいたしますので、今しばらくお待ちいただけましたら幸いです。
              <br />
              何卒宜しくお願い致します。
            </div>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ プレセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
            <!-- ↓ 残り、開始終了日時、注文数、購入ボタン         ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <BuySection
              v-if="detail.presale_amount > 0"
              type="PRESALE"
              :startTime="detail.presale_start_time"
              :startTimeInMilliseconds="presaleStartTime"
              :endTime="detail.presale_end_time"
              :endTimeInMilliseconds="presaleEndTime"
              :chainId="detail.chain_id"
              :price="detail.presale_price"
              :totalQuantity="detail.presale_amount"
              :sold="detail.presale_sold_amount"
              :maxSalePerUser="detail.presale_max_purchase"
              :contractId="detail.contract_project_id"
              @buying="onBuying"
              :isWhitelisted="canBuy"
              :calendarUrl="calendarUrl(false)"
              :stopped="detail.status === 4"
              :isReady="detail.status_code === 'READY'"
              :isTBA="detail.is_presale_tba"
              :isPriceTBA="detail.is_presale_price_tba"
              :target="presaleTarget"
              :transferUnsoldDisabled="detail.transfer_unsold_disabled"
              :tokenAddress="detail.token_address"
              :allowance="currentAllowance"
              @onApproveToken="approve"
              :hasAffiliate="detail.is_affiliate_enabled"
              :isMaintaining="isMaintaining"
            ></BuySection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ プレセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
            <!-- ↑ 残り、開始終了日時、注文数、購入ボタン         ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ==+=========+=========+=========+=========+=========+====== -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓ パブリックセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
            <!-- ↓ 残り、開始終了日時、注文数、購入ボタン               ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <BuySection
              v-if="detail.publicsale_amount > 0"
              type="PUBLIC_SALE"
              :startTime="detail.publicsale_start_time"
              :startTimeInMilliseconds="publicSaleStartTime"
              :endTime="detail.publicsale_end_time"
              :endTimeInMilliseconds="publicSaleEndTime"
              :chainId="detail.chain_id"
              :price="detail.publicsale_price"
              :totalQuantity="publicSaleTotalAmount"
              :sold="detail.publicsale_sold_amount"
              :maxSalePerUser="detail.publicsale_max_purchase"
              :contractId="detail.contract_project_id"
              @buying="onBuying"
              :calendarUrl="calendarUrl(true)"
              :stopped="detail.status === 4"
              :isReady="detail.status_code === 'READY'"
              :isTBA="detail.is_publicsale_tba"
              :isPriceTBA="detail.is_publicsale_price_tba"
              :target="publicSaleTarget"
              :transferUnsoldDisabled="detail.transfer_unsold_disabled"
              :tokenAddress="detail.token_address"
              :allowance="currentAllowance"
              @onApproveToken="approve"
              :hasAffiliate="detail.is_affiliate_enabled"
              :isMaintaining="isMaintaining"
            ></BuySection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑ パブリックセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
            <!-- ↑ 残り、開始終了日時、注文数、購入ボタン               ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </div>
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 商品説明 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <section class="header md:flex space-x-8 align-middle mt-4">
            <div class="general w-full grow">
              <div class="description text-lg whitespace-pre-line">
                <div v-html="$sanitize(showDescription)" />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ 右端赤文字「ホワイトリスト対象」又は ↓ -->
              <!-- ↓ 「ホワイトリスト対象外」             ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div class="flex space-x-3 mt-4">
                <!--
                <div
                  v-if="detail.presale_amount > 0"
                  class="relative -top-2 text-right w-full grow whitelist-status whitespace-nowrap self-end justify-end md:justify-end"
                >
                  <StatusTag :type="canBuy ? 'WHITELISTED' : 'NOT_WHITELISTED'"></StatusTag>
                </div>
                -->
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ 右端赤文字「ホワイトリスト対象」又は ↑ -->
              <!-- ↑ 「ホワイトリスト対象外」             ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </div>
          </section>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 商品説明 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ 不明。is_affiliate_enabled が真のときの処理 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <section
            v-if="detail.is_affiliate_enabled && referral_code"
            class="mt-16 text-white"
          >
            <div class="font-semibold text-3xl">{{ $t('REF_CODE_TITLE') }}</div>
            <div class="flex items-end gap-8 mt-5">
              <div class="w-28 p-1 text-center bg-white">
                <qr-code
                  :text="qrLink"
                  :size="104"
                  error-level="H"
                ></qr-code>
              </div>
              <div class="text-lg">
                <div>{{ $t('REF_CODE_LABEL') }}:&nbsp;{{ referral_code }}</div>
                <a
                  :href="qrLink"
                  target="_blank"
                  class="underline"
                  >{{ qrLink }}</a
                >
                {{ detail.presale_sold_amount + detail.publicsale_sold_amount }}/{{
                  totalSaleAmount
                }}
              </div>
            </div>
          </section>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ 不明。is_affiliate_enabled が真のときの処理 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ プレセール、販売量 > 0 時のみ、価格、販売総数、↓ -->
          <!-- ↓ 残り、開始終了日時、注文数、購入ボタン         ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <BuySection
            v-if="detail.presale_amount > 0"
            type="PRESALE"
            :startTime="detail.presale_start_time"
            :startTimeInMilliseconds="presaleStartTime"
            :endTime="detail.presale_end_time"
            :endTimeInMilliseconds="presaleEndTime"
            :chainId="detail.chain_id"
            :price="detail.presale_price"
            :totalQuantity="detail.presale_amount"
            :sold="detail.presale_sold_amount"
            :maxSalePerUser="detail.presale_max_purchase"
            :contractId="detail.contract_project_id"
            @buying="onBuying"
            :isWhitelisted="canBuy"
            :calendarUrl="calendarUrl(false)"
            :stopped="detail.status === 4"
            :isReady="detail.status_code === 'READY'"
            :isTBA="detail.is_presale_tba"
            :isPriceTBA="detail.is_presale_price_tba"
            :target="presaleTarget"
            :transferUnsoldDisabled="detail.transfer_unsold_disabled"
            :tokenAddress="detail.token_address"
            :allowance="currentAllowance"
            @onApproveToken="approve"
            :hasAffiliate="detail.is_affiliate_enabled"
            :isMaintaining="isMaintaining"
          ></BuySection>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ プレセール、販売量 > 0 時のみ、価格、販売総数、↑ -->
          <!-- ↑ 残り、開始終了日時、注文数、購入ボタン         ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ====+=========+=========+=========+=========+=========+====== -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓ パブリックセール、販売量 >0 時のみ、価格、販売総数、↓ -->
          <!-- ↓ 残り、開始終了日時、注文数、購入ボタン              ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <BuySection
            v-if="detail.publicsale_amount > 0"
            type="PUBLIC_SALE"
            :startTime="detail.publicsale_start_time"
            :startTimeInMilliseconds="publicSaleStartTime"
            :endTime="detail.publicsale_end_time"
            :endTimeInMilliseconds="publicSaleEndTime"
            :chainId="detail.chain_id"
            :price="detail.publicsale_price"
            :totalQuantity="publicSaleTotalAmount"
            :sold="detail.publicsale_sold_amount"
            :maxSalePerUser="detail.publicsale_max_purchase"
            :contractId="detail.contract_project_id"
            @buying="onBuying"
            :calendarUrl="calendarUrl(true)"
            :stopped="detail.status === 4"
            :isReady="detail.status_code === 'READY'"
            :isTBA="detail.is_publicsale_tba"
            :isPriceTBA="detail.is_publicsale_price_tba"
            :target="publicSaleTarget"
            :transferUnsoldDisabled="detail.transfer_unsold_disabled"
            :tokenAddress="detail.token_address"
            :allowance="currentAllowance"
            @onApproveToken="approve"
            :hasAffiliate="detail.is_affiliate_enabled"
            :isMaintaining="isMaintaining"
          ></BuySection>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑ パブリックセール、販売量 >0 時のみ、価格、販売総数、↑ -->
          <!-- ↑ 残り、開始終了日時、注文数、購入ボタン              ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </div>
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- ↑ 購入フォーム。データが有るときのみ表示。↑ -->
        <!-- ------+---------+---------+---------+---------+---------+------ -->        
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 画面幅 640px 以上の場合、右列（2:3の3）。↑ -->
      <!-- ↑ 画面幅 640px 未満の場合、画面幅一杯。    ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ========+=========+=========+=========+=========+=========+====== -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ チェーン・規格・コントラクトアドレス。↓ -->
      <!-- ↓ 画像幅 640px 未満時のみ表示。         ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <div class="visible sm:hidden w-4/5 mx-auto border border-gray-400">
        <div class="flex items-center">
          <div class="font-bold">チェーン</div> &emsp; 
          {{ getChainName(detail.chain_id) }}
        </div>
        <div class="flex items-center">
          <div class="font-bold">規格</div> &emsp;
          ECR-{{ detail.contract_type }}
        </div>
        <div class="flex items-center">
          <div class="font-bold">コントラクトアドレス</div> &ensp;
          <div class="text-xs">{{ detail.contract_address }}</div>
        </div>
      </div>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ チェーン・規格・コントラクトアドレス。↑ -->
      <!-- ↑ 画像幅 640px 未満時のみ表示。         ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑ 画面幅 640px 以上の場合、2:3 の二列にしている。 ↑ -->
    <!-- ↑ 画面幅 640px 未満の場合、一列。                 ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </a-spin>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
// パンくずリスト
import BreadcrumbList from '@/components/common/BreadcrumbList.vue';
// chain_idとチェーン名を紐づける。
import { CHAIN_ID } from '@/constants/chains.constant';
import GlobalIcon from '@/components/icons/global.icon.vue';
import XIcon from '@/components/icons/x.icon.vue';
// import TwitterIcon from '@/components/icons/twitter_outlined.icon.vue';
// import StatusTag from '@/components/nft/NFTDetail/StatusTag.vue';
import BuySection from '@/components/nft/NFTDetail/BuySection.vue';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { SITE_URL } from '@/env';
import moment from 'moment';
import { generateCalendarLink } from '@/utils/link.util';
// import CombinedTargetBar from '@/components/common/CombinedTargetBar.vue';
import { getUserReferralCode } from '@/services/blockchain/project';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'NFTDetailPage',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    BuySection,
    // StatusTag,
    BreadcrumbList,
    GlobalIcon,
    XIcon,
    // TwitterIcon,
    // CombinedTargetBar,
  }, // End of components: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      SITE_URL,
      referral_code: '',
      isMaintaining: false,
    }; // End of return {...}
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  metaInfo() {
    return {
      title: `${this.detail.name} - Zaif INO`,
      // meta: [
      //     {
      //         vmid: "description",
      //         property: "description",
      //         content: this.detail.description,
      //     },
      //     {
      //         property: "og:title",
      //         content: `${this.detail.name} - Zaif INO`,
      //         vmid: "og:title",
      //     },
      //     {
      //         property: "og:site",
      //         content: "Zaif INO",
      //         vmid: "og:site",
      //     },
      //     {
      //         property: "og:description",
      //         vmid: "og:description",

      //         content: this.detail.description,
      //     },
      //     {
      //         property: "og:site_name",
      //         content: `${this.detail.name} - Zaif INO`,
      //         vmid: "og:site_name",
      //     },
      //     {
      //         property: "og:image",
      //         content: this.detail.banner_url,
      //         vmid: "og:image",
      //     },
      //     {
      //         property: "twitter:site",
      //         vmid: "twitter:site",

      //         content: this.detail.twitter_url,
      //     },
      //     {
      //         property: "twitter:card",

      //         vmid: "twitter:card",
      //         content: "summary",
      //     },
      //     {
      //         property: "twitter:image",
      //         content: this.detail.banner_url,
      //         vmid: "twitter:image",
      //     },
      // ],
    }; // End of return {...}
  }, // End of metaInfo() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapGetters({
      accessToken: 'wallet/accessToken',
      account: 'wallet/account',
      allowance: 'allowance/allowance',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      locale: (state) => state?.setting.locale,
      detail: (state) => state.projects.selectedProject,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
      allowances: (state) => state?.allowance.data || {},
      hierarchy: (state) => state.hierarchy.hierarchy,
      name_en  : (state) => state.hierarchy.name_en,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    qrLink() {
      return `${this.SITE_URL}/NFT/${this.$route.params.id}?code=${this.referral_code}`;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleStartTime() {
      return moment(this.detail.presale_start_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleStartTime() {
      return moment(this.detail.publicsale_start_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleEndTime() {
      return moment(this.detail.presale_end_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleEndTime() {
      return moment(this.detail.publicsale_end_time).valueOf();
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPresaleEnded() {
      return (
        this.detail.presale_end_time &&
        moment(this.detail.presale_end_time).valueOf() <= new Date().getTime()
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleTotalAmount() {
      return (
        Number(this.detail.publicsale_amount) +
        (this.isPresaleEnded && !this.detail.transfer_unsold_disabled
          ? Number(this.detail.presale_amount) - Number(this.detail.presale_sold_amount)
          : 0)
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    canBuy() {
      // is_whitelisted は存在しない。
      return this.detail.is_whitelisted || !this.detail.has_whitelist;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    formattedDescription() {
      return this.detail.description;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    presaleTarget() {
      return this.detail.presale_target || this.detail.presale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    publicSaleTarget() {
      return this.detail.publicsale_target || this.detail.publicsale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    totalSaleAmount() {
      return this.detail.presale_amount + this.detail.publicsale_amount;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showName() {
      return this.locale === 'ja' && this.detail.name_ja ? this.detail.name_ja : this.detail.name;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showDescription() {
      return this.locale === 'ja' && this.detail.description_ja
        ? this.detail.description_ja
        : this.detail.description;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isChainMatched() {
      return this.connectedChainId === this.detail.chain_id;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    currentAllowance() {
      return this.allowance(this.detail.token_address);
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  async mounted() {
    if ([64, 65].includes(Number(this.$route.params.id))) {
      this.isMaintaining = true;
    }
    // --+---------+---------+---------+---------+---------+---------+---------E
    await this.fetchDetail(this.$route.params.id);
    // alert(`id=${this.detail.id},name=${this.detail.name}, name_ja=${this.detail.name_ja}, this.detail.publicsale_sold_amount=${JSON.stringify(this.detail.publicsale_sold_amount)}`);
    // --+---------+---------+---------+---------+---------+---------+---------E
    console.debug('MOUNTED');
    // --+---------+---------+---------+---------+---------+---------+---------E
    if (this.detail) {
      if (this.detail.is_hidden) {
        this.$router.push('/');
      }
      this.setToConnectChain(this.detail.chain_id);
    }
    // --+---------+---------+---------+---------+---------+---------+---------E
    //alert(`*** this.detail.hierarchy=${this.detail.hierarchy}: mounted(): detail.page.vue ***`);
    if (this.detail.hierarchy == undefined) {this.detail.hierarchy=''}
    // --+---------+---------+---------+---------+---------+---------+---------E
    this.setHierarchy({hierarchy: this.detail.hierarchy,
                        name_en: this.detail.name,
                        name_ja: this.detail.name_ja
    });
  }, // End of async mounted() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    async account(newVal, oldVal) {
      console.log('detected change', newVal, oldVal);
      this.fetchDetail(this.$route.params.id);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async chainOrAccountChanged(newVal, oldVal) {
      if (newVal[1] !== oldVal[1] || newVal[0] !== oldVal[0]) {
        this.fetchDetail(this.$route.params.id);
        await this.getAllowanceCoin();
        await this.getUserReferralCode(newVal);
      }
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async connectedChainId(newVal, oldVal) {
      console.log('connectedChainId', newVal, oldVal);
      if (newVal !== oldVal) {
        await this.getAllowanceCoin();
        await this.getUserReferralCode();
      }
    },
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    getChainName(id) {
      let stResult = '不明';
      for(let key of Object.keys(CHAIN_ID)) {
        if (CHAIN_ID[key] == id) {
          stResult = key;
          break;
        }
      } // End of for(let key of CHAIN_ID.keys(obj)) {...}
      return stResult;
    }, // End of getChainName(id) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // --+---------+---------+---------+---------+---------+---------+---------E
    generateCalendarLink,
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
      getAllowance: 'allowance/getAllowance',
      approveToken: 'allowance/approveToken',
      setHierarchy: 'hierarchy/setHierarchy',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapMutations({
      updateProjectDetail: 'projects/UPDATE_SELECTED_PROJECT',
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    async fetchDetail(id) {
      this.loading = true;
      await this.fetchProjectDetail(id);
      this.loading = false;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async onBuying(res) {
      this.loading = res;
      if (res === false) {
        await this.getAllowanceCoin();
        await this.getUserReferralCode();
      }
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    calendarUrl(isPublicSale) {
      const saleType = isPublicSale ? 'Public Sale' : 'Presale';
      const startTime = isPublicSale
        ? this.detail.publicsale_start_time
        : this.detail.presale_start_time;
      const endTime = isPublicSale ? this.detail.publicsale_end_time : this.detail.presale_end_time;
      const { description, name, id } = this.detail;
      const refLink = `${SITE_URL}/NFT/${id}`;
      return this.generateCalendarLink(name, saleType, startTime, endTime, description, refLink);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getAllowanceCoin() {
      if (!this.detail.token_address || !this.isChainMatched) {
        return;
      }
      await this.getAllowance({
        tokenAddress: this.detail.token_address,
      });
      console.debug({ allowance: this.currentAllowance });
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async approve() {
      if (!this.detail.token_address || !this.isChainMatched || !this.$connector.provider) {
        return;
      }
      await this.approveToken({
        tokenAddress: this.detail.token_address,
        provider: this.$connector.provider,
      });
      console.debug({ allowance: this.currentAllowance });
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    async getUserReferralCode(userAddress) {
      if (
        !this.detail.is_affiliate_enabled ||
        !this.$connector?.provider ||
        this.connectedChainId !== this.detail.chain_id ||
        !this.detail.contract_project_id
      ) {
        this.referral_code = '';
      } else {
        this.referral_code = await getUserReferralCode({
          provider: this.$connector?.provider,
          chainId: this.connectedChainId,
          projectId: this.detail.contract_project_id,
          userAddress: userAddress || this.account,
        });
      } // End of if (...) {...} else {...}
    }, // End of async getUserReferralCode(userAddress) {...}
}, // End of methods: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  destroyed() {
    this.updateProjectDetail({});
  },
};
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
/* .nft-detail-page-img-container { */
/*   max-width: 1140px; */
/*   @apply mx-auto; */
/* } */
::v-deep .nft-detail-page {
  @apply overflow-hidden;
  @apply px-4;
  @screen md {
    @apply px-4;
  }
  @screen xl {
    width: 1140px;
    @apply px-0;
  }
  .description {
    a {
      color: #40a9ff;
    }
  }
  &-breadcrumb {
    .ant-breadcrumb {
      span {
        .ant-breadcrumb-link {
          @apply text-white;
          @apply font-medium;
        }
        .ant-breadcrumb-separator {
          color: #fff;
        }
      }
    }
  }
  .combined-target-bar {
    @screen xxs {
      padding-left: 16px;
      padding-right: 16px;
    }
    @screen md {
      padding-left: 32px;
      padding-right: 32px;
    }
    @apply px-1;
    @apply mb-4;
  }
}
</style>
