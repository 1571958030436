<template>
  <div
    v-if="projectDetail && projectDetail.id"
    class="project-detail-page pb-8"
  >
    <button
      class="text-sm mb-8 cursor-pointer"
      @click="() => $router.push('/manage/projects')"
    >
      &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
    </button>
    <section class="text-3xl mb-12 font-semibold">{{ $t('ADMIN_DETAIL_PAGE_TITLE') }}</section>
    <a-button
      v-if="connectedChainId !== projectDetail.chain_id"
      class="flex items-center px-4 text-base py-2 rounded-lg border border-white w-max"
      @click="$root.$emit('switch-metamask-chain', projectDetail.chain_id)"
    >
      {{ $t('BUTTON_CONNECT_CHAIN', { chainName: CHAIN_INFO[projectDetail.chain_id].name }) }}
    </a-button>
    <section
      v-else
      class="project-info"
    >
      <div
        class="flex items-center justify-end bg-section-bg pt-6 pr-6"
        v-if="shouldShowBtnEdit"
      >
        <router-link
          :to="`/manage/projects/${projectDetail.id}/edit`"
          class="flex items-center justify-center rounded-xl border border-white p-2 w-24 edit-btn !hover:text-primary !hover:border-primary text-base font-bold"
        >
          <EditIcon class="mr-2" /> {{ $t('EDIT') }}</router-link
        >
      </div>
      <ProjectViewSection
        title="PROJECT_GENERAL_SECTION_TITLE"
        :dataSource="projectDetail"
        :columns="generalSection"
        :class="{ '!pt-0': shouldShowBtnEdit }"
      >
        <div slot="name">
          <span>{{ showName }}</span>
        </div>
        <div slot="detail">
          <div class="break-words whitespace-pre-line">
            <div v-html="$sanitize(showDescription)"></div>
          </div>
        </div>
        <div
          slot="chain_id"
          slot-scope="record"
        >
          <div target="_blank">
            {{ CHAIN_INFO[record.value].name }}
          </div>
        </div>
        <div
          slot="image_uploaded_name"
          slot-scope="{ value, record }"
        >
          <a
            class="text-xl font-semibold"
            target="_blank"
            download
            :href="record.nft_images_url"
            >{{ value }}</a
          >
        </div>
        <div
          slot="payment"
         >
         <div v-if="projectDetail.payment === 1">
            {{ '有効' }}
          </div>
          <div v-else>
            {{ '無効' }}
          </div>
        </div>
        <div
          slot="json_uploaded_name"
          slot-scope="{ value, record }"
        >
          <a
            class="text-xl font-semibold"
            target="_blank"
            download
            :href="record.nft_json_url"
            >{{ value }}</a
          >
        </div>

        <div
          slot="website_url"
          slot-scope="record"
        >
          <a
            target="_blank"
            :href="record.value"
            class="font-semibold block break-all"
            >{{ record.value }}</a
          >
        </div>
        <div
          slot="twitter_url"
          slot-scope="record"
        >
          <a
            target="_blank"
            :href="record.value"
            class="font-semibold block break-all"
            >{{ record.value }}</a
          >
        </div>
        <div
          slot="address"
          slot-scope="{ value }"
        >
          <div class="text-18px font-semibold">
            {{ value }}
          </div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_REVEAL_DATE_SECTION_TITLE"
        :columns="dateTimeSection"
      >
        <div
          slot="date"
          slot-scope="props"
          class="text-xl font-semibold"
        >
          <div v-if="props.record.is_revealed">
            {{ props.value | toJST }}
          </div>
          <div v-else>-</div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PRESALE_SECTION_TITLE"
        :columns="presaleSection"
      >
        <div
          slot="start"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_presale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="end"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_presale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="amount"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="price"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          <div v-if="projectDetail.is_presale_price_tba">{{ $t('TBA') }}</div>
          <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
        </div>
        <div
          slot="whitelist"
          slot-scope="{ value, record }"
        >
          <a
            class="text-xl font-semibold"
            target="_blank"
            download
            :href="record.presale_whitelist_url"
            >{{ value }}</a
          >
          <div
            v-if="canUploadWhitelist"
            class="mt-2 relative -left-4"
          >
            <div class="flex justify-between">
              <div>
                <BaseUploader
                  id="whitelist-json"
                  v-model="whitelist"
                  :shouldLimitFileSize="false"
                  accept=".csv"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          slot="max_purchase"
          slot-scope="record"
        >
          <a class="text-xl font-semibold">{{ record.value | toCurrency }}</a>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PRESALE_RESULT_SECTION_TITLE"
        :columns="presaleStatistics"
      >
        <div
          slot="sold"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="revenue"
          class="text-xl font-semibold"
        >
          {{ presaleRevenue | toCurrency }}&nbsp;{{ symbol }}
        </div>
        <div
          slot="available"
          class="text-xl font-semibold"
        >
          {{ presaleAvailable | toCurrency }}
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PUBSALE_SECTION_TITLE"
        :columns="publicSaleSection"
      >
        <div
          slot="start"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_publicsale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="end"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_publicsale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="amount"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="price"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          <div v-if="projectDetail.is_publicsale_price_tba">{{ $t('TBA') }}</div>
          <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PUBSALE_RESULT_SECTION_TITLE"
        :columns="publicSaleStatistics"
      >
        <div
          slot="sold"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="revenue"
          class="text-xl font-semibold"
        >
          {{ publicSaleRevenue | toCurrency }}&nbsp;{{ symbol }}
        </div>
        <div
          slot="available"
          class="text-xl font-semibold"
        >
          {{ publicSaleAvailable | toCurrency }}
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_FEE_SECTION_TITLE"
        :columns="feeSection"
      >
        <div
          slot="fee"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ Number(record.value || 0) }}&nbsp;%
        </div>
        <div
          slot="dev_fee_rate"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ Number(record.value || 0) }}&nbsp;%
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        v-if="projectDetail.status !== 2 || projectDetail.status_code === 'ERROR'"
        :dataSource="projectDetail"
        title="PROJECT_STATUS_SECTION_TITLE"
        :columns="actionSection"
      >
        <div
          slot="stop"
          slot-scope="record"
          class="text-xl font-semibold"
        >
          {{ record.status }}
          <div class="toggle-btn">
            <button
              @click="showHideProject(false)"
              :class="{ 'toggle-btn__active': !projectDetail.is_hidden }"
              :disabled="loading"
            >
              {{ $t('PROJECT_STATUS_SHOW_RADIO_BTN') }}
            </button>
            <button
              @click="showHideProject(true)"
              :class="{ 'toggle-btn__active': projectDetail.is_hidden }"
              :disabled="loading"
            >
              {{ $t('PROJECT_STATUS_HIDE_RADIO_BTN') }}
            </button>
          </div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_DEPLOYED_CONTRACT_SECTION_TITLE"
        :columns="contractSection"
      >
        <div
          slot="contract_address"
          class="font-medium"
        >
          <div v-if="projectDetail.contract_address">
            {{ projectDetail.contract_address }}
          </div>
          <div v-else>-</div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROCEEDS_SECTION_TITLE"
        :columns="proceedsSection"
      >
        <div
          slot="pending_fee"
          class="text-xl font-semibold"
        >
          <div>
            <span
              ><CryptoIcon
                class="mr-2"
                :symbol="symbol"
              ></CryptoIcon
              >{{ pendingFee }}&nbsp;{{ symbol }}</span
            >
            <span
              class="xl:ml-24 lg:ml-16 ml-8"
              v-if="pendingFee > 0"
            >
              <button
                @click="verifyInput"
                class="text-white bg-primary py-2 px-8 text-2xl mt-4 rounded-2xl font-semibold"
              >
                {{ $t('WITHDRAW_BTN') }}
              </button>
            </span>
          </div>
        </div>
      </ProjectViewSection>
      <template v-if="account && userRole === 'admin'">
        <div class="bg-section-bg flex items-center justify-center pb-20 pt-10">
          <button
            v-if="projectDetail.status < 2 || projectDetail.status_code === 'ERROR'"
            class="flex items-center px-4 md:px-10 text-2xl py-2 rounded-lg bg-primary"
            :disabled="loading"
            @click="deleteAProject"
          >
            <DeleteIcon class="text-white mr-2 w-8 h-8" />
            {{ $t('DELETE_BTN') }}
          </button>
          <div
            v-if="
              (projectDetail.status === 3 && projectDetail.status_code !== 'FINISHED') ||
              projectDetail.status === 2
            "
            class="flex items-center action__buttons"
          >
            <button
              class="flex items-center px-4 md:px-10 text-2xl py-2 rounded-lg border border-white mr-6 w-200px"
              @click="$router.push('/manage/projects')"
              :disabled="loading"
            >
              {{ $t('BACK_BTN') }}
            </button>
            <button
              class="flex items-center px-4 md:px-10 text-2xl py-2 rounded-lg border border-white w-200px"
              :disabled="loading"
              @click="handleStopProject"
            >
              <PauseIcon class="mr-2 w-8 h-8" />
              {{ $t('STOP_BTN') }}
            </button>
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { BigNumber } from '@/utils/bignumber.util';
import DeleteIcon from '../components/icons/box_delete.icon.vue';
import PauseIcon from '../components/icons/pause.icon.vue';
import { stopProject, getPendingFee, claimFee, getSoldAmount } from '@/services/blockchain/project';
import { deleteProject, toggleShowHide, uploadWhitelist } from '@/services/projects';
import UtilNotify from '@/utils/notify.util';
import EditIcon from '../components/icons/edit.icon.vue';
import BaseUploader from '@/components/common/BaseUploader.vue';
import { compareAddress } from '@/utils/address.util';
import CryptoIcon from '@/components/common/CryptoIcon.vue';
import moment from 'moment';

const generalSection = [
  {
    key: 'symbol',
    title: 'PROJECT_SYMBOL_LABEL',
  },
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL',
    scopedSlots: {
      value: 'name',
    },
  },
  {
    key: 'formattedDescription',
    title: 'PROJECT_DESCRIPTION_LABEL',
    scopedSlots: {
      value: 'detail',
    },
  },
  {
    key: 'chain_id',
    title: 'PROJECT_CHAIN_LABEL',
    scopedSlots: {
      value: 'chain_id',
    },
  },
  {
    key: 'payment',
    title: 'BUTTON_PIMENT_BUY',
    scopedSlots: { value: 'payment' },
  },
  {
    key: 'image_uploaded_name',
    title: 'PROJECT_IMG_UPLOAD_LABEL',
    scopedSlots: {
      value: 'image_uploaded_name',
    },
  },
  {
    key: 'json_uploaded_name',
    title: 'PROJECT_CSV_UPLOAD_LABEL',
    scopedSlots: {
      value: 'json_uploaded_name',
    },
  },
  {
    key: 'website_url',
    title: 'PROJECT_WEBSITE_LABEL',
    scopedSlots: {
      value: 'website_url',
    },
  },
  {
    key: 'twitter_url',
    title: 'PROJECT_TWITTER_LABEL',
    scopedSlots: { value: 'twitter_url' },
  },
  {
    key: 'owner_address',
    title: 'PROJECT_OWNER_ADDRESS_LABEL',
  },
];
const dateTimeSection = [
  {
    key: 'revealed_at',
    title: 'PROJECT_REVEALE_DATE_LABEL',
    scopedSlots: { value: 'date' },
  },
];
const presaleSection = [
  {
    key: 'presale_start_time',
    title: 'PROJECT_PRESALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'presale_end_time',
    title: 'PROJECT_PRESALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'presale_amount',
    title: 'PROJECT_PRESALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'presale_price',
    title: 'PROJECT_PRESALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'whitelist_uploaded_name',
    title: 'PROJECT_PRESALE_WHITELIST_LABEL',
    scopedSlots: { value: 'whitelist' },
  },
  {
    key: 'presale_max_purchase',
    title: 'PROJECT_PRESALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchase' },
  },
];
const presaleStatistics = [
  {
    key: 'presale_sold_amount',
    title: 'PROJECT_PRESALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  {
    key: 'presale_revenue',
    title: 'PROJECT_PRESALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  {
    key: 'presale_available',
    title: 'PROJECT_PRESALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
];
const publicSaleSection = [
  {
    key: 'publicsale_start_time',
    title: 'PROJECT_PUBSALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'publicsale_end_time',
    title: 'PROJECT_PUBSALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'publicsale_amount',
    title: 'PROJECT_PUBSALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'publicsale_price',
    title: 'PROJECT_PUBSALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },

  {
    key: 'publicsale_max_purchase',
    title: 'PROJECT_PUBSALE_MAX_PURCHASE_LABEL',
  },
];
const publicSaleStatistics = [
  {
    key: 'publicsale_sold_amount',
    title: 'PROJECT_PUBSALE_SOLD_LABEL',
    scopedSlots: { value: 'sold' },
  },
  {
    key: 'public_sale_revenue',
    title: 'PROJECT_PUBSALE_REVENUE_LABEL',
    scopedSlots: { value: 'revenue' },
  },
  {
    key: 'public_sale_available',
    title: 'PROJECT_PUBSALE_AVAILABLE_LABEL',
    scopedSlots: { value: 'available' },
  },
];
const feeSection = [
  {
    key: 'fee',
    title: 'PROJECT_FEE_LABEL',
    scopedSlots: { value: 'fee' },
  },
  {
    key: 'dev_fee_rate',
    title: 'PROJECT_REFERRAL_FEE_LABEL',
    scopedSlots: { value: 'dev_fee_rate' },
  },
];
const actionSection = [
  {
    key: 'stop',
    title: 'PROJECT_STATUS_STOP_LABEL',
    scopedSlots: { value: 'stop' },
  },
];
const contractSection = [
  {
    key: 'contract_address',
    title: '',
    scopedSlots: { title: 'contract_address', value: 'contract' },
  },
];
const proceedsSection = [
  {
    key: 'pending_fee',
    title: '',
    scopedSlots: { value: 'pending_fee' },
  },
];

export default {
  name: 'ProjectDetailPage',
  props: {},
  components: {
    ProjectViewSection,
    DeleteIcon,
    PauseIcon,
    EditIcon,
    BaseUploader,
    CryptoIcon,
  },
  data() {
    return {
      generalSection,
      dateTimeSection,
      presaleSection,
      presaleStatistics,
      publicSaleSection,
      publicSaleStatistics,
      feeSection,
      actionSection,
      contractSection,
      proceedsSection,
      CHAIN_INFO,
      loading: false,
      data: {
        name: 'Project name Project name',
        description:
          'Discription will be entered. A description will be included.Discription will be entered. A description will be included.Discription will be entered. A description will be included.',
        chain: 'ETH(ERC721)',
        img: 'filename.zip',
        json: 'json.csv',
        website: 'https://mint.lemons.club/',
        twitter: 'https://twitter.com/opensea',
        reveal_date: new Date(),
        presale_start: new Date(),
        presale_end: new Date(),
        presale_price: 0.08,
        presale_amount: 5000,
        presale_whitelist: 'whitelist.csv',
        presale_max_purchasability: 3,
        presale_sold: 1500,
        presale_revenue: 5000,
        presale_available: 3500,
        public_sale_start: new Date(),
        public_sale_end: new Date(),
        public_sale_price: 0.08,
        public_sale_amount: 5000,
        public_sale_whitelist: 'whitelist.csv',
        public_sale_max_purchasability: 3,
        public_sale_sold: 1500,
        public_sale_revenue: 5000,
        public_sale_available: 3500,
        fee_ratio: 0.05,
      },
      whitelist: null,
      pendingFee: 0,
      correctHash:'907901949602b76f628918351073d216b2a043c1a61e316fc3f11446caab7d00',
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
      userRole: 'wallet/loggedUserRole',
    }),
    ...mapState({
      locale: (state) => state?.setting.locale,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        this.setToConnectChain(data.chain_id);
        return data;
      }
      return {};
    },
    symbol() {
      if (!this.projectDetail) return 'ETH';
      return this.projectDetail.token_address
        ? TOKEN_SYMBOLS[this.projectDetail.chain_id][this.projectDetail.token_address]
        : CHAIN_INFO[this.projectDetail.chain_id].nativeCurrency.symbol;
    },
    presaleRevenue() {
      return new BigNumber(this.projectDetail.presale_price)
        .times(this.projectDetail.presale_sold_amount)
        .toString();
    },
    presaleAvailable() {
      return new BigNumber(this.projectDetail.presale_amount)
        .minus(this.projectDetail.presale_sold_amount)
        .toString();
    },
    publicSaleRevenue() {
      return new BigNumber(this.projectDetail.publicsale_price)
        .times(this.projectDetail.publicsale_sold_amount)
        .toString();
    },
    isPresaleEnded() {
      return (
        this.projectDetail.presale_end_time &&
        moment(this.projectDetail.presale_end_time).valueOf() <= new Date().getTime()
      );
    },
    publicSaleTotalAmount() {
      return (
        Number(this.projectDetail.publicsale_amount) +
        (this.isPresaleEnded && !this.projectDetail.transfer_unsold_disabled
          ? Number(this.projectDetail.presale_amount) -
            Number(this.projectDetail.presale_sold_amount)
          : 0)
      );
    },
    publicSaleAvailable() {
      return this.publicSaleTotalAmount - Number(this.projectDetail.publicsale_sold_amount);
    },
    shouldShowBtnEdit() {
      return (
        (this.projectDetail.status <= 1 || this.projectDetail.status === 5) &&
        this.userRole === 'admin'
      );
    },
    canUploadWhitelist() {
      return (
        this.userRole === 'admin' ||
        this.compareAddress(this.projectDetail.owner_address, this.account)
      );
    },
    showName() {
      return this.locale === 'ja' && this.projectDetail.name_ja
        ? this.projectDetail.name_ja
        : this.projectDetail.name;
    },
    showDescription() {
      return this.locale === 'ja' && this.projectDetail.description_ja
        ? this.projectDetail.description_ja
        : this.projectDetail.description;
    },
  },
  watch: {
    projectDetail(detail) {
      if (detail && document) {
        document.title = `${detail.name} - Zaif INO`;
      }
    },
    async whitelist(newFile) {
      if (this.projectDetail.id && newFile && Object.keys(newFile).length > 0) {
        await this.onUpdateWhitelist(this.projectDetail.id, newFile.file);
      }
    },
    async connectedChainId() {
      await this.getPendingFee();
      await this.fetchProjectDetailById();
    },
    async account() {
      await this.getPendingFee();
      await this.fetchProjectDetailById();
    },
  },
  async created() {
    await this.fetchProjectDetailById();
  },
  async mounted() {
    await this.getPendingFee();
  },
  methods: {
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    ...mapMutations({
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    compareAddress,
    async fetchProjectDetailById() {
      try {
        const { id } = this.$route.params;
        await this.fetchProjectDetail(id);
      } catch (error) {
        console.debug({ error });
        UtilNotify.error(this.$notify, error);
        this.$router.push('/manage/projects');
      }
    },
    async deleteAProject() {
      try {
        this.loading = true;
        await deleteProject(this.projectDetail.id);
        UtilNotify.success(this.$notify, { message: 'PROJECT_DELETE_SUCCESS_NOTIFICATION' });
        this.$router.push('/manage/projects');
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      } finally {
        this.loading = false;
      }
    },
    async showHideProject(hidden) {
      try {
        if (hidden === this.projectDetail.is_hidden) return;
        this.loading = true;
        console.debug({ hidden });
        await toggleShowHide(this.projectDetail.id, hidden);
        UtilNotify.success(this.$notify, {
          message: hidden
            ? 'PROJECT_HIDE_SUCCESS_NOTIFICATION'
            : 'PROJECT_SHOW_SUCCESS_NOTIFICATION',
        });
        this.$router.push('/manage/projects');
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      } finally {
        this.loading = false;
      }
    },
    async handleStopProject() {
      try {
        this.loading = true;
        if (this.projectDetail.status_code !== 'ERROR' && this.projectDetail.status >= 2) {
          const provider = this.$connector.provider;
          const { contract_project_id: projectId } = this.projectDetail;
          await stopProject({
            provider,
            account: this.account,
            chainId: this.connectedChainId,
            projectId,
          });
          UtilNotify.success(this.$notify, { message: 'NOTIFICATION_SUCCESS_TRANSACTION' });
          this.$router.push('/manage/projects');
        } else {
          UtilNotify.error(this.$notify, {
            message: 'PROJECT_NOT_READY_STOP_NOTIFICATION',
          });
        }
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      } finally {
        this.loading = false;
      }
    },
    async onUpdateWhitelist(projectId, file) {
      try {
        const formData = new FormData();
        formData.append('whitelist', file);
        const data = await uploadWhitelist(projectId, formData);
        if (data) {
          UtilNotify.success(this.$notify, {
            message: 'PROJECT_UPDATE_WHITELIST_SUCCESS_NOTIFICATION',
          });
          this.$router.push('/manage/projects');
        }
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      }
    },
    async getPendingFee() {
      const provider = this.$connector?.provider;
      const { contract_project_id: projectId, chain_id: chainId } = this.projectDetail;
      if (!provider || !this.account || this.connectedChainId !== chainId || !projectId) {
        this.pendingFee = 0;
      } else {
        this.pendingFee = await getPendingFee({
          provider,
          account: this.account,
          chainId: this.connectedChainId,
          projectId,
        });
      }
    },
    async getSoldAmount() {
      const provider = this.$connector?.provider;
      const { contract_project_id: projectId, chain_id: chainId } = this.projectDetail;
      if (!provider || this.connectedChainId !== chainId || !projectId) {
        return {
          presale_sold_amount: 0,
          publicsale_sold_amount: 0,
        };
      } else {
        const presale_sold_amount = await getSoldAmount({
          provider,
          chainId: this.connectedChainId,
          isPresale: true,
          projectId,
        });
        const publicsale_sold_amount = await getSoldAmount({
          provider,
          chainId: this.connectedChainId,
          isPresale: false,
          projectId,
        });
        return {
          presale_sold_amount,
          publicsale_sold_amount,
        };
      }
    },
    async withdrawFee() {
      try {
        const provider = this.$connector.provider;
        await claimFee({
          provider,
          account: this.account,
          projectId: this.projectDetail.contract_project_id,
          chainId: this.projectDetail.chain_id,
        });
        await this.getPendingFee();
        UtilNotify.success(
          this.$notify,
          { message: 'NOTIFICATION_SUCCESS_TRANSACTION' },
          this.$modal
        );
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      }
    },
    async hashText(text) {
      const encoder = new TextEncoder();
      const data = encoder.encode(text);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
    },
    async verifyInput() {
      let UserInput = prompt("パスワードを入力して下さい:","");
      const inputHash = await this.hashText(UserInput);
      if (inputHash === this.correctHash) {
        alert('Authenticated successfully');
        this.withdrawFee();
      } else {
        alert('Authentication failed');
      }
    }
  },
};
</script>

<style lang="scss">
.action__buttons {
  button.w-200px {
    @screen md {
      width: 200px;
      @apply flex items-center justify-center;
    }
  }
  button {
    height: 50px;
  }
}

.toggle-btn {
  @apply flex items-center w-max;
  border-radius: 14px;
  padding: 2px 4px;
  background-color: #21134d;
  button {
    border-radius: 14px;
    @apply px-6 py-2 font-semibold text-2xl;
    &:first-child {
      @apply mr-2;
    }
  }
  &__active {
    @apply bg-primary;
  }
}
</style>
