<template>
    <div class="dropdown" @click="toggleDropdown" @blur="closeDropdown" tabindex="0">
      <button class="dropbtn">
        ヘルプ
        <span class="help">
          <span class="rotated-icon">&lt;</span>
        </span>
      </button>
      <div v-if="isOpen" class="dropdown-content">

        <a class="dropdown-item border-b" @click="handleNavigation('/about')">
          {{ $t('ABOUT_ZAIFINO') }}
          <span class="drawer-menu-icon">></span>
        </a>
        <a class="dropdown-item" @click="FAQ('/about')">
          {{ $t('FAQ') }}
          <span class="drawer-menu-icon">></span>
        </a>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    handleNavigation(route) {
      // 同じページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
        window.scrollTo({
            // ページの最初の位置にスクロール
            top: 0,
            // スムーズスクロール
            behavior: 'smooth'
        })
      }) 
      //異なるページにいる場合
      } else {
        this.$router.push(route);
      }
      // ドロップダウンを閉じる
      this.closeDropdown();
    },  
    FAQ(route) {
     // 既にaboutページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
        window.scrollTo({
            // ページの高さの半分の位置にスクロール
            top: document.body.scrollHeight / 2,
            // スムーズスクロール
            behavior: 'smooth'
        })
      }) 
      //異なるページにいる場合
      } else {
        this.$router.push('/about').then(() => {
          // ページ遷移後に、ページの半分までスクロールする
          this.$nextTick(() => {
            window.scrollTo({
              // ページの高さの半分の位置にスクロール
              top: document.body.scrollHeight / 2,
              // スムーズスクロール
              behavior: 'smooth'
            });
          });
        });
      }
    }
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  color: black;
  font-weight: bold;
  padding: 2px 10px;
  font-size: 12px;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  letter-spacing: 0.1em;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ccc;
}

.dropdown-content a {
  color: black;
  padding: 12px 12px;
  text-decoration: none;
  display: flex; /* 文言とアイコンを横並びにする */
  align-items: center;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.help {
  display: inline-block;
  margin-left: 5px;
}
.rotated-icon {
  display: inline-block;
  transform: rotate(-90deg); /* 90度回転 */
  font-size: 14px; /* サイズ調整 */
  color: black; /* 色の設定 */
}
.dropdown-item{
  display: flex; /* 文言とアイコンを横並びにする */
  align-items: center;
  justify-content: space-between; /* テキストとアイコンを左右に分ける */
}
.drawer-menu-icon {
  width: 18px;
  height: 18px;
  background: #9B9DA1;
  color: white;
  border-radius: 1000000px;
  margin-left: 2px;
}
.border-b {
  border-bottom: 1px solid #ddd;
}
</style>