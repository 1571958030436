<template>
  <div
    class="input-number-custom"
    :class="error ? 'input-number-custom--error' : ''"
  >
    <div
      class="input-number-custom__label"
      :class="labelClass"
    >
      <span v-if="label">{{ label }}</span>
      <slot
        v-else
        name="label"
      ></slot>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div class="input-number-custom__input w-12">
      <span class="input-number-custom__input__prefix">
        <slot name="prefix"></slot>
      </span>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓ 数値入力テキストボックス ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <input
        style="width: 6rem; padding-left: 2rem; text-align: left;
                background: #EEE; border-radius: 9999px; "
        ref="input"
        v-cleave="directives"
        :class="inputClass"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        @keyup="onKeyup"
        @blur="$emit('blur', $event.target.value)"
        @focus="$emit('focus', $event.target, value)"
      />
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑ 数値入力テキストボックス ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <span class="input-number-custom__input__suffix">
        <slot name="suffix"></slot>
      </span>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div
      class="input-number-custom__error"
      :class="
        error ? 'input-number-custom__error--visible' : 'input-number-custom__error--invisible'
      "
    >
      {{ error }}
    </div>
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
const toRawValue = (input) => {
  const strToNumberStr = (val) => val.replace(/[^0-9-.]/g, '');
  if (typeof input === 'number') {
    if (isFinite(input)) return String(input);
    return '';
  }
  // ----+---------+---------+---------+---------+---------+---------+---------E
  if (typeof input === 'string') {
    if (input === '') return '';

    if (!isFinite(strToNumberStr(input))) return '';
    return input.replace(/[^0-9-.]/g, '');
  }
  // ----+---------+---------+---------+---------+---------+---------+---------E
  return '';
};
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    label: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    type: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    value: {
      type: [String, Number],
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    error: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    disabled: {
      type: Boolean,
      default: false,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    decimals: {
      type: Number,
      default: 18,
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    option: {
      type: Object,
      default: () => ({}),
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    labelClass: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    inputClass: {
      type: String,
      default: '',
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    variant: {
      type: String,
      default: 'main',
    },
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      rawValue: '',
    };
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    directives() {
      const defaultOptions = {
        numeralPositiveOnly: true,
        numeral: true,
        numeralDecimalScale: this.decimals,
        onValueChanged: this.onValueChange,
        numeralThousandsGroupStyle: 'none',
      }; // End of const defaultOptions = {...}
      return { ...defaultOptions, ...this.options };
    }, // End of directives() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    cleave() {
      return this.$refs.input?.cleave;
    },
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        const currentValue = this.rawValue;
        if (currentValue !== newVal) {
          setTimeout(() => {
            if (this.cleave) {
              this.cleave.setRawValue(`${toRawValue(newVal)}`);
            }
          }); // End of setTimeout(() => {...})
        } // End of if (currentValue !== newVal) {...}
      }, // End of handler(newVal) {...}
    }, // End of value: {...}
  }, // End of watch: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    onValueChange(e) {
      if (this.rawValue !== e.target.rawValue) {
        this.rawValue = e.target.rawValue;
        this.$emit('input', `${toRawValue(this.rawValue)}`);
      }
    }, // End of  onValueChange(e) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    onKeyup(event) {
      this.$emit('keyup', event.target.value);
    },
  }, // End of methodes: {...}
};
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style lang="scss">
.input-number-custom {
  border: none;
  /* background-color: orange; */

  input {
    width: 100%;
    background: transparent;
    @apply text-black;
    border-radius: 8px;
    padding-top: 14px;
    padding-bottom: 14px;
    @apply pr-10;
    &::placeholder {
      @apply text-gray-500 font-semibold;
    }
    &:focus {
      @apply border-none outline-none;
    }
    &:disabled {
      @apply opacity-50;
    }
  }
  &__error {
    color: #f90000;
    margin-top: 0.25rem;
  }
  &--error {
    input {
      border-color: #f90000 !important;
    }
  }
  &__label {
    @apply text-xl leading-5 font-bold text-primary;
    @apply mb-1;
  }
  &__input {
    @apply relative;
    &__suffix {
      @apply absolute;
      @apply right-3 top-3;
    }
    &__prefix {
      @apply absolute;
      @apply left-3 top-3;
    }
  }
}
</style>
