<template>
  <div class="nft-card-detail text-white">
    <section
      @click="$router.push(`/NFT/${data.id}`)"
      class="nft-card-detail__img-container relative cursor-pointer"
    >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- 売ることができない場合は画像等を暗くする。 -->
      <!--
      <div
        :class="[
          canNotBuy ? 'opacity-30 absolute cursor-not-allowed' : 'cursor-pointer',
          'w-full h-full',
        ]"
      >
      -->
      <div
        :class="[
          canNotBuy ? 'opacity-30 absolut' : 'cursor-pointer',
          'w-full h-full',
        ]"
      >
      <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- 画像 -->
        <img
          class="img_container"
          :src="data.thumbnail_url"
        />
        <!-- ------+---------+---------+---------+---------+---------+------ -->
        <!-- 画像の左上の「最新」「準備中」「近日公開」「コントラクト作成済」-->
        <!--「終了」「プレセール中」「販売中」                               -->
        <div class="absolute top-0 left-0 m-2">
          <div>
            <CardTag :type="statusCode"></CardTag>
          </div>
        </div>
      </div>
      <!-- 完売時に表示されるオーバーレイ -->
      <div
        v-if="isSoldOut"
        class="soldout-finish-overlay absolute inset-0 flex items-center justify-center"
      >
        <soldOutIcon />
      </div>
      <!-- 終了時に表示されるオーバーレイ -->
      <div
        v-else-if="isEnd"
        class="soldout-finish-overlay absolute inset-0 flex items-center justify-center"
      >
        <soldFinishIcon />
      </div>
    </section>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓ 画像の下の情報 ↓ -->
    <!--
    <div
      :class="[
        'nft-card-detail__body grid grid-cols-1 px-6 py-4 rounded-b-xl',
        canNotBuy ? 'opacity-30' : '',
      ]"
    >
    -->
    <div class="nft-card-detail__body grid grid-cols-1 px-6 py-4 rounded-b-xl">
      <div class="nft-card-detail__info card-section">
        <div class="title text-sm font-semibold w-full">
          {{ showName }}
        </div>
        <!-- 画像とＮＦＴ名称の下のＮＦＴ説明 -->
        <div class="description text-2xs w-full">
          {{ htmlToFormattedText($sanitize(showDescription)) }}
        </div>
      </div>
      <div class="sale-and-price-section">
        <div class="sale_text">
            <div class="text-sm">{{ isOnPresale ? $t('PRESALE_TEXT') : $t('PUBLIC_SALE_TEXT') }}</div>
        </div>
        <section class="quantity-price-section">
          <div class="flex tight-leading items-center justify-between">
            <!-- 価格表示 -->
            <div class="value text-left">
              <span class="price">
                {{ isPriceTBA ? $t('TBA') : price }}
              </span>
              <span class="currency">
                {{ isPriceTBA ? '' : currency }}
              </span>
            </div>
            <!-- 残り数量 (--------------------※現在は全体の数量となっているため修正の必要有------------------)-->
            <div class="supply text-right flex items-center">
              <div>{{ $t('LEFTOVER') }}</div>
              <div class="ml-1">{{ remainAmount }}</div>
              <div v-if="locale === 'ja'" class="ml-1">{{ $t('REMAIN') }}</div>
            </div>
          </div>
          <!-- 日付部分 -->
          <div class="flex tight-leading">
            <div class="property w-32 font-semibold">{{ $t('STARTDATE') }}</div>
            <div v-if="!isTBA" class="value date w-full grow text-right overflow-hidden">
              {{ startTimeMilli | toJST('YYYY/MM/DD HH:mm (ddd)') }}
            </div>
            <div v-else class="value w-full grow text-right overflow-hidden">
              {{ $t('TBA') }}
            </div>
          </div>
          <div class="flex tight-leading">
            <div class="property blue w-32 font-semibold">{{ $t('ENDDATE') }}</div>
            <div v-if="!isTBA" class="value date w-full grow text-right overflow-hidden">
              {{ endTimeMilli | toJST('YYYY/MM/DD HH:mm (ddd)') }}
            </div>
            <div v-else class="value w-full grow text-right overflow-hidden">
              {{ $t('TBA') }}
            </div>
          </div>
        </section>
      </div>
      <section class="card-section">
          <div>
            <button
              @click="() => $router.push(`/NFT/${data.id}`)"
              class="
                'disabled:cursor-not-allowed rounded-full font-medium button-scale disabled:bg-disabled disabled:text-black',
                hover:opacity-70 mt-4,
              "
            >
              <!-- 購入可能かどうかでボタンのテキストを変えられる。    -->
              <!-- 2024/07/03現在、同じ文言「購入はこちら」にしている。-->
              <div class="buytext" v-if="canNotBuy">{{ $t('BUY_HERE') }}</div>
              <div class="buytext" v-else>{{ $t('BUY_HERE') }}</div>
            </button>
          </div>
      </section>
    </div>
    <!-- ↑ 画像の下の情報 ↑ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import CardTag from './CardTag.vue';
import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import { SITE_URL } from '@/env';
import moment from 'moment';
import { generateCalendarLink } from '@/utils/link.util';
import htmlToFormattedText from 'html-to-formatted-text';
import { mapState } from 'vuex';
import soldOutIcon from '@/components/icons/sold-out.icon.vue';
import soldFinishIcon from '@/components/icons/sold-finish.icon.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  name: 'NFTCardItem',
  // ----+---------+---------+---------+---------+---------+---------+---------E
  components: {
    CardTag,
    soldOutIcon,
    soldFinishIcon
  }, // End of components: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  props: {
    data: {
      type: Object,
      required: true,
    },
  }, // End of props: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  data() {
    return {
      CHAIN_INFO,
      computationRefreshKey: false,
      endTimeMilli: 0,
      SITE_URL,
    };
  }, // End of data() {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  created() {
    this.getTime();
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    isOnPresale() {
      if (this.data.is_presale_tba && this.data.is_publicsale_tba) {
        return true;
      }
      if(this.data.presale_amount && !this.data.publicsale_amount){
        return true;
      }
      return (
        this.data.presale_end_time &&
        moment(this.data.presale_end_time).valueOf() > new Date().getTime()
      );
    }, // End of isOnPresale() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    statusCode() {
      if (this.data.status_code !== 'ON_SALE') {
        return this.data.status_code;
      }
      return this.isOnPresale ? 'ON_PRESALE' : 'ON_SALE';
    }, // End of statusCode() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    isPriceTBA() {
      return this.isOnPresale ? this.data.is_presale_price_tba : this.data.is_publicsale_price_tba;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isTBA() {
      if (this.data.is_presale_tba && this.data.is_publicsale_tba) {
        return true;
      } else if (this.data.is_presale_tba && !this.data.is_publicsale_tba) {
        if (this.isOnPresale) {
          return true;
        } else {
          return false;
        } // End of if (this.isOnPresale) {...}
      } else if (!this.data.is_presale_tba && this.data.is_publicsale_tba) {
        if (this.isOnPresale) {
          return false;
        } else {
          return true;
        } // End of if (this.isOnPresale) {...}
      } else {
        return false;
      } // End of if (...) {...} else if (...) {...} ... else {...}
    }, // End of isTBA() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    calendarUrl() {
      const saleType = this.isOnPresale ? this.$t('PRESALE_TEXT') : this.$t('PUBLIC_SALE_TEXT');
      const startTime = this.isOnPresale
        ? this.data.presale_start_time
        : this.data.publicsale_start_time;
      const endTime = this.isOnPresale ? this.data.presale_end_time : this.data.publicsale_end_time;
      const { description, name, id } = this.data;
      const refLink = `${SITE_URL}/NFT/${id}`;
      return this.generateCalendarLink(name, saleType, startTime, endTime, description, refLink);
    }, // End of  calendarUrl() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    price() {
      return this.isOnPresale ? this.data.presale_price : this.data.publicsale_price;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    supply() {
      if (this.isOnPresale) {
        return this.data.presale_amount;
      } else {
        return (
          this.data.publicsale_amount +
          (!this.data.transfer_unsold_disabled
            ? this.data.presale_amount - this.data.presale_sold_amount
            : 0)
        );
      } // End of if (this.isOnPresale) {...} else {...}
    }, // End of supply() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    total() {
      return Number(this.data.presale_amount) + Number(this.data.publicsale_amount);
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    dateSale() {
      return this.isOnPresale ? this.data.presale_start_time : this.data.publicsale_start_time;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    currency() {
      if (!this.data) return 'ETH';
      return this.data.token_address
        ? TOKEN_SYMBOLS[this.data.chain_id][this.data.token_address]
        : CHAIN_INFO[this.data.chain_id].nativeCurrency.symbol;
    }, // End of  currency() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    remainAmount(){
      return this.isOnPresale ? this.data.presale_amount - this.data.presale_sold_amount : this.publicSaleTotalAmount - this.data.publicsale_sold_amount;
    },
    publicSaleTotalAmount() {
      return (
        Number(this.data.publicsale_amount) +
        (!this.isOnPresale && !this.data.transfer_unsold_disabled
          ? Number(this.data.presale_amount) - Number(this.data.presale_sold_amount)
          : 0)
      );
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isEnd() {
      return this.data?.status_code === 'FINISHED';
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isSoldOut() {
      return (
        Number(this.data.presale_amount) + Number(this.data.publicsale_amount) ===
        Number(this.data.presale_sold_amount) + Number(this.data.publicsale_sold_amount)
      );
    }, // End of isSoldOut() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    canNotBuy() {
      return this.isEnd || this.isSoldOut;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    isReady() {
      return this.data.status_code === 'READY';
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showName() {
      return this.locale === 'ja' && this.data.name_ja ? this.data.name_ja : this.data.name;
    },
    // --+---------+---------+---------+---------+---------+---------+---------E
    showDescription() {
      return this.locale === 'ja' && this.data.description_ja
        ? this.data.description_ja
        : this.data.description;
    }, // End of showDescription() {...}
  }, // End of computed: {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  methods: {
    generateCalendarLink,
    // --+---------+---------+---------+---------+---------+---------+---------E
    onFinish() {
      console.log('on finish');
      setTimeout(() => {
        this.getTime();
      }, 1000);
    }, // End of onFinish() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    getTime() {
      let onPresale = false;
      if(this.data.presale_amount && !this.data.publicsale_amount){
        onPresale = true;
      }
      else if(this.data.presale_end_time &&
        moment(this.data.presale_end_time).valueOf() > new Date().getTime()){
          onPresale = true;
        }
      const start = onPresale
        ? moment(this.data.presale_start_time).valueOf()
        : moment(this.data.publicsale_start_time).valueOf();
      // ---------+---------+---------+---------+---------+---------+---------+E
      const end = onPresale
        ? moment(this.data.presale_end_time).valueOf()
        : moment(this.data.publicsale_end_time).valueOf();
      this.endTimeMilli = end;
      this.startTimeMilli = start;
    },
    htmlToFormattedText,
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
.nft-card-detail {
  @screen md {
    width: 340px;
  }
  border: 2px solid #D3D3D3;
  width: 100%;
  border-radius: 50px;
  margin: auto;
  .soldout-finish-overlay {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  .img_container {
    aspect-ratio: 1 / 1;
    width: 90%;
    height: auto;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover; /* コンテンツをコンテナのサイズに合わせてトリミング */
    border-radius: 50px;
  }
  &__body {
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column; // アイテムを縦方向に配置
    justify-content: flex-start; // アイテムを上端から順に配置
    align-items: stretch; // アイテムの幅をコンテナに合わせて伸縮
    padding: 6px 0;
    border-radius: 50px;
  }
  &__info {
    margin: 10px;
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 40px;
      font-size: 1.2rem;
      margin-bottom: 7px;
    }

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 62px;
      font-size: 0.8rem;
    }
  }
  .card-section {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .sale-and-price-section {
    width: 90%;
    margin: 0 auto; /* 親要素を中央に配置 */
    border: 1px solid rgb(200, 200, 200);
    border-radius: 0.75rem;
  } 
  
  .sale_text {
    font-weight: bold;
    padding-bottom: 0;
    padding-left: 10px; /* テキストを右に10pxずらす */
    text-transform: uppercase;
    background-color: #e2e8f0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-top: 1px solid #e2e8f0;
    border-left: 1px solid #e2e8f0;
    border-right: 1px solid #e2e8f0;
  }
  .quantity-price-section {
    height: 105px;
    font-size: 0.9rem;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* 要素を上下に均等に配置 */
  }
  .quantity-price-section .price {
    color: #C5003F;

    font-size: 1.5rem; /* 価格の数字を大きく */
    font-weight: bold;
  }

  .quantity-price-section .currency {
    font-size: 1rem; /* 通貨シンボルは小さく */
    color: #C5003F;
    margin-left: 0.2rem;
  }

  .quantity-price-section .supply {
    font-size: 1rem;
    color: #333;
  }
}
.date {
  font-size: 0.8rem;
  font-family:  'Open Sans';
}
@media (max-width: 768px) {
  .date {
    font-size: 0.9rem;
    font-family: 'Poppins', sans-serif;
  }
}
.buytext{
  color: white;
  width: auto;
}
.icon-scale {
  @apply w-7 h-7;
  @screen xxs {
    @apply w-10 h-10;
  }
  @screen xs {
    @apply w-12 h-12;
  }
  @screen md {
    @apply w-7 h-7;
  }
}
.button-scale {
  background-color: #FF0085;
  @apply w-52 h-7;
  @screen xxs {
    @apply w-48 h-10;
  }
  @screen xs {
    @apply w-64 h-12;
  }
  @screen sm {
    @apply w-80 h-12;
  }
  @screen md {
    @apply w-52 h-7;
  }
}
</style>
