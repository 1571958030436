var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userRole === 'admin')?_c('div',{staticClass:"create-project-page pb-8"},[_c('button',{staticClass:"text-sm text-black mb-8 cursor-pointer",on:{"click":function () {
          _vm.$router.push('/manage/projects');
        }}},[_vm._v(" << "+_vm._s(_vm.$t('BACK_BTN'))+" ")]),_c('section',{staticClass:"text-3xl mb-20 font-semibold text-black"},[_vm._v(_vm._s(_vm.$t('PROJECT_EDIT_TITLE')))]),_c('ValidationObserver',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitForm.apply(null, arguments)}}},[_c('section',{staticClass:"project-info"},[_c('ProjectViewSection',{staticClass:"!mb-0",attrs:{"title":"PROJECT_GENERAL_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.generalSection}},[_c('div',{staticClass:"flex items-center",attrs:{"slot":"project_img","value":""},slot:"project_img"},[_c('ValidationProvider',{attrs:{"rules":"","name":"PROJECT_IMG"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseUploader',{attrs:{"id":"project_banner","suggestSizeText":""},model:{value:(_vm.form.banner),callback:function ($$v) {_vm.$set(_vm.form, "banner", $$v)},expression:"form.banner"}}),(!_vm.form.banner)?_c('div',[_c('a',{staticClass:"text-xl font-semibold",attrs:{"target":"_blank","download":"","href":_vm.projectDetail.banner_url}},[_vm._v(_vm._s(_vm.projectDetail.banner_uploaded_name))])]):_vm._e()],1),_c('ErrorMessage',{attrs:{"id":"project_img","error":errors[0]}})]}}],null,false,3673316995)})],1),_c('div',{staticClass:"flex items-center",attrs:{"slot":"project_thumbnail","value":""},slot:"project_thumbnail"},[_c('ValidationProvider',{attrs:{"rules":"","name":"PROJECT_LISTING_IMG"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseUploader',{attrs:{"id":"project_thumbnail","suggestSizeText":""},model:{value:(_vm.form.thumbnail),callback:function ($$v) {_vm.$set(_vm.form, "thumbnail", $$v)},expression:"form.thumbnail"}}),(!_vm.form.thumbnail)?_c('div',[_c('a',{staticClass:"text-xl font-semibold",attrs:{"target":"_blank","download":"","href":_vm.projectDetail.thumbnail_url}},[_vm._v(_vm._s(_vm.projectDetail.thumbnail_uploaded_name))])]):_vm._e()],1),_c('ErrorMessage',{attrs:{"id":"project_thumbnail","error":errors[0]}})]}}],null,false,4018748244)})],1),_c('div',{attrs:{"slot":"name"},slot:"name"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"PROJECT_NAME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-input',{attrs:{"maxLength":100,"placeholder":_vm.$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('ErrorMessage',{attrs:{"id":"name","error":errors[0]}})]}}],null,false,23282280)})],1),_c('div',{attrs:{"slot":"label_name"},slot:"label_name"},[_vm._v(_vm._s(_vm.$t('PROJECT_NAME_LABEL'))+" (en)")]),_c('div',{attrs:{"slot":"label_name_ja"},slot:"label_name_ja"},[_vm._v(_vm._s(_vm.$t('PROJECT_NAME_LABEL'))+" (ja)")]),_c('div',{attrs:{"slot":"name_ja"},slot:"name_ja"},[_c('ValidationProvider',{attrs:{"rules":"","name":"PROJECT_NAME_JAPANESE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-input',{attrs:{"maxLength":100,"placeholder":_vm.$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })},model:{value:(_vm.form.name_ja),callback:function ($$v) {_vm.$set(_vm.form, "name_ja", $$v)},expression:"form.name_ja"}}),_c('ErrorMessage',{attrs:{"id":"name_ja","error":errors[0]}})]}}],null,false,353771208)})],1),_c('div',{attrs:{"slot":"description"},slot:"description"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"PROJECT_DESCRIPTION"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('Editor',{attrs:{"maxLength":2400},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('ErrorMessage',{attrs:{"id":"description","error":errors[0]}})]}}],null,false,2811808241)})],1),_c('div',{attrs:{"slot":"label_description"},slot:"label_description"},[_vm._v(_vm._s(_vm.$t('PROJECT_DESCRIPTION_LABEL'))+" (en)")]),_c('div',{attrs:{"slot":"label_description_ja"},slot:"label_description_ja"},[_vm._v(_vm._s(_vm.$t('PROJECT_DESCRIPTION_LABEL'))+" (ja)")]),_c('div',{attrs:{"slot":"description_ja"},slot:"description_ja"},[_c('ValidationProvider',{attrs:{"rules":"","name":"PROJECT_DESCRIPTION_JAPANESE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('Editor',{attrs:{"maxLength":2400},model:{value:(_vm.form.description_ja),callback:function ($$v) {_vm.$set(_vm.form, "description_ja", $$v)},expression:"form.description_ja"}}),_c('ErrorMessage',{attrs:{"id":"description_ja","error":errors[0]}})]}}],null,false,1960372177)})],1),_c('div',{attrs:{"slot":"chain"},slot:"chain"},[_c('a-select',{staticClass:"w-80",attrs:{"value":_vm.form.chain_id + (_vm.form.token_address ? ("##" + (_vm.form.token_address)) : '')},on:{"change":_vm.onChainChange}},[_c('span',{staticClass:"text-4xl text-black relative -top-3.5 left-2",attrs:{"slot":"suffixIcon"},slot:"suffixIcon"},[_vm._v("▾")]),_vm._l((_vm.chains),function(chain){return _c('a-select-option',{key:chain.value,attrs:{"value":chain.value}},[_vm._v(" "+_vm._s(chain.key)+" ")])})],2)],1),_c('div',{attrs:{"slot":"credit_payment"},slot:"credit_payment"},[_c('a-checkbox',{model:{value:(_vm.form.credit_payment),callback:function ($$v) {_vm.$set(_vm.form, "credit_payment", $$v)},expression:"form.credit_payment"}},[_vm._v(_vm._s(_vm.$t('BUTTON_PIMENT_BUY')))])],1),_c('div',{attrs:{"slot":"symbol"},slot:"symbol"},[_c('a-input',{attrs:{"maxLength":20,"placeholder":_vm.$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })},model:{value:(_vm.form.symbol),callback:function ($$v) {_vm.$set(_vm.form, "symbol", $$v)},expression:"form.symbol"}})],1),_c('div',{attrs:{"slot":"img"},slot:"img"},[_c('ValidationProvider',{attrs:{"rules":"","name":"NFT_IMG"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center mr-4"},[_c('BaseUploader',{attrs:{"id":"images-zip","shouldLimitFileSize":false,"accept":"application/x-zip-compressed, application/zip"},model:{value:(_vm.form.images),callback:function ($$v) {_vm.$set(_vm.form, "images", $$v)},expression:"form.images"}}),_c('div',{staticClass:"ml-4"},[_vm._v("*"+_vm._s(_vm.$t('PROJECT_ZIP_ONLY')))])],1),(!_vm.form.images)?_c('div',{staticClass:"truncate flex-1"},[_c('a',{staticClass:"text-xl font-semibold truncate",attrs:{"target":"_blank","download":"","href":_vm.projectDetail.nft_images_url}},[_vm._v(_vm._s(_vm.projectDetail.image_uploaded_name))])]):_vm._e()]),_c('ErrorMessage',{attrs:{"id":"nft-image","error":errors[0]}})]}}],null,false,347516652)})],1),_c('div',{staticClass:"w-full",attrs:{"slot":"json"},slot:"json"},[_c('div',{staticClass:"flex justify-between w-full"},[_c('div',{staticClass:"flex-1 mr-4"},[_c('ValidationProvider',{attrs:{"rules":"","name":"CSV_UPLOAD"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center flex-wrap"},[_c('div',{staticClass:"flex items-center mr-4"},[_c('BaseUploader',{attrs:{"id":"images-json","shouldLimitFileSize":false,"accept":".csv"},model:{value:(_vm.form.json),callback:function ($$v) {_vm.$set(_vm.form, "json", $$v)},expression:"form.json"}}),_c('div',{staticClass:"ml-4"},[_vm._v("*"+_vm._s(_vm.$t('PROJECT_CSV_ONLY')))])],1),(!_vm.form.json)?_c('div',{staticClass:"flex-1 truncate"},[_c('a',{staticClass:"text-xl font-semibold w-full",attrs:{"target":"_blank","download":"","href":_vm.projectDetail.nft_json_url}},[_vm._v(_vm._s(_vm.projectDetail.json_uploaded_name))])]):_vm._e()]),_c('ErrorMessage',{attrs:{"id":"nft-json","error":errors[0]}})]}}],null,false,3543827020)})],1),_c('a',{staticClass:"text-right underline relative top-4 cursor-pointer text-xs w-max",attrs:{"href":_vm.JSON_SAMPLE_URL,"target":"_blank","download":""}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_SAMPLE_DOWNLOAD_REF'))+" ")])])]),_c('div',{attrs:{"slot":"website"},slot:"website"},[_c('ValidationProvider',{attrs:{"rules":"required|url","name":"WEBSITE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-input',{attrs:{"placeholder":"https://zaif-ino.com/"},model:{value:(_vm.form.website_url),callback:function ($$v) {_vm.$set(_vm.form, "website_url", $$v)},expression:"form.website_url"}}),_c('ErrorMessage',{attrs:{"id":"Website","error":errors[0]}})]}}],null,false,959670355)})],1),_c('div',{attrs:{"slot":"twitter"},slot:"twitter"},[_c('ValidationProvider',{attrs:{"rules":"required|url","name":"TWITTER"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-input',{attrs:{"placeholder":"https://twitter.com/zaif_ino"},model:{value:(_vm.form.twitter_url),callback:function ($$v) {_vm.$set(_vm.form, "twitter_url", $$v)},expression:"form.twitter_url"}}),_c('ErrorMessage',{attrs:{"id":"twitter","error":errors[0]}})]}}],null,false,1854261564)})],1),_c('div',{attrs:{"slot":"address"},slot:"address"},[_c('ValidationProvider',{attrs:{"rules":"required|checkTokenAddress","name":"OWNER_ADDRESS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('a-input',{attrs:{"placeholder":_vm.$t('PROJECT_OWNER_ADDRESS_PLACEHOLDER')},model:{value:(_vm.form.owner_address),callback:function ($$v) {_vm.$set(_vm.form, "owner_address", $$v)},expression:"form.owner_address"}}),_c('ErrorMessage',{attrs:{"id":"owner_address","error":errors[0]}})]}}],null,false,212744232)})],1)]),_c('ProjectViewSection',{staticClass:"text-xl font-semibold !mb-0",attrs:{"title":"PROJECT_SETTING_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.settingSection}},[_c('div',{attrs:{"slot":"reveal"},slot:"reveal"},[_c('div',{staticClass:"flex text-xl font-semibold w-full"},[_c('a-radio-group',{model:{value:(_vm.form.can_reveal),callback:function ($$v) {_vm.$set(_vm.form, "can_reveal", $$v)},expression:"form.can_reveal"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_YES'))+" ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_NO'))+" ")])],1)],1),(_vm.form.can_reveal)?_c('div',{staticClass:"mt-8"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"REVEAL_IMG"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('BaseUploader',{attrs:{"id":"reveal_thumbnail_uploader"},model:{value:(_vm.form.reveal_thumbnail),callback:function ($$v) {_vm.$set(_vm.form, "reveal_thumbnail", $$v)},expression:"form.reveal_thumbnail"}}),_c('ErrorMessage',{attrs:{"id":"reveal_thumbnail","error":errors[0]}})]}}],null,false,2605394883)})],1):_vm._e()]),_c('div',{attrs:{"slot":"presale_remaining"},slot:"presale_remaining"},[_c('div',{attrs:{"slot":"transfer_unsold_disabled"},slot:"transfer_unsold_disabled"},[_c('div',{staticClass:"font-medium text-lg mb-2"},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_REMAINING_ACTION_TEXT'))+" ")]),_c('div',{staticClass:"text-xl font-semibold w-full"},[_c('a-radio-group',{model:{value:(_vm.form.transfer_unsold_disabled),callback:function ($$v) {_vm.$set(_vm.form, "transfer_unsold_disabled", $$v)},expression:"form.transfer_unsold_disabled"}},[_c('a-radio',{attrs:{"value":false}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_YES'))+" ")]),_c('a-radio',{attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_NO'))+" ")])],1),(!_vm.form.transfer_unsold_disabled)?_c('div',{staticClass:"mt-4"},[_c('ValidationProvider',{attrs:{"rules":{
                        required: true,
                        min_value: 1,
                        max_value: _vm.totalAmount ? _vm.totalAmount : Number.MAX_SAFE_INTEGER,
                      },"name":"NFT_SALES_VOLUME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"placeholder":_vm.$t('PROJECT_COMBINED_TARGET_LABEL'),"error":errors[0]},model:{value:(_vm.form.target),callback:function ($$v) {_vm.$set(_vm.form, "target", $$v)},expression:"form.target"}})]}}],null,false,3429101362)})],1):_vm._e()],1)])])]),_c('ProjectViewSection',{staticClass:"!mb-0",attrs:{"title":"PROJECT_PRESALE_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.presaleSectionFiltered}},[_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"start"},slot:"start"},[_c('div',{staticClass:"flex items-center"},[_c('ValidationProvider',{attrs:{"rules":{
                    required: !_vm.form.is_presale_tba,
                  },"name":"PRESALE_START_TIME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseDatepicker',{attrs:{"id":"presale_start","disabled":_vm.form.is_presale_tba},model:{value:(_vm.form.presale_start_time),callback:function ($$v) {_vm.$set(_vm.form, "presale_start_time", $$v)},expression:"form.presale_start_time"}}),(_vm.form.presale_start_time)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm._f("toJST")(_vm.form.presale_start_time))+" ")]):_vm._e(),_c('div',{staticClass:"ml-4"},[_c('a-checkbox',{attrs:{"checked":_vm.form.is_presale_tba},on:{"change":function($event){_vm.form.is_presale_tba = !_vm.form.is_presale_tba}}},[_vm._v(_vm._s(_vm.$t('TBA')))])],1)],1),_c('ErrorMessage',{attrs:{"id":"presale_start","error":errors[0]}})]}}],null,false,39009295)})],1)]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"end"},slot:"end"},[_c('div',[_c('ValidationProvider',{attrs:{"rules":{
                    required: !_vm.form.is_presale_tba,
                  },"name":"PRESALE_END_TIME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseDatepicker',{attrs:{"id":"presale_end","disabled":_vm.form.is_presale_tba},model:{value:(_vm.form.presale_end_time),callback:function ($$v) {_vm.$set(_vm.form, "presale_end_time", $$v)},expression:"form.presale_end_time"}}),(_vm.form.presale_end_time)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm._f("toJST")(_vm.form.presale_end_time))+" ")]):_vm._e()],1),_c('ErrorMessage',{attrs:{"id":"presale_end","error":_vm.preSaleTimeError ? _vm.$t(_vm.preSaleTimeError) : errors[0]}})]}}],null,false,534062626)})],1)]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"amount"},slot:"amount"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: !Number(_vm.form.publicsale_amount),
                  min_value: 0,
                  max_value: 30000,
                },"name":"PRESALE_AMOUNT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"placeholder":"0 ~ 30000","error":errors[0]},model:{value:(_vm.form.presale_amount),callback:function ($$v) {_vm.$set(_vm.form, "presale_amount", $$v)},expression:"form.presale_amount"}})]}}],null,false,2161937848)})],1),(_vm.form.transfer_unsold_disabled)?_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"presale_target"},slot:"presale_target"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min_value: 1,
                  max_value: _vm.form.presale_amount,
                },"name":"PRESALE_TARGET"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"error":errors[0]},model:{value:(_vm.form.presale_target),callback:function ($$v) {_vm.$set(_vm.form, "presale_target", $$v)},expression:"form.presale_target"}})]}}],null,false,2726835725)})],1):_vm._e(),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"price"},slot:"price"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: !_vm.form.is_presale_price_tba,
                },"name":"PRESALE_PRICE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":18,"disabled":_vm.form.is_presale_price_tba,"error":errors[0],"placeholder":"0 ~"},model:{value:(_vm.form.presale_price),callback:function ($$v) {_vm.$set(_vm.form, "presale_price", $$v)},expression:"form.presale_price"}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.symbol)+" ")]),_c('div',{staticClass:"ml-4"},[_c('a-checkbox',{attrs:{"checked":_vm.form.is_presale_price_tba},on:{"change":function($event){_vm.form.is_presale_price_tba = !_vm.form.is_presale_price_tba}}},[_vm._v(_vm._s(_vm.$t('TBA')))])],1)],1)]}}],null,false,2755205617)})],1),_c('div',{staticClass:"w-full",attrs:{"slot":"whitelist"},slot:"whitelist"},[_c('ValidationProvider',{attrs:{"rules":"","name":"WHITELIST"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex flex-1 items-center mr-4"},[_c('BaseUploader',{attrs:{"id":"whitelist-json","shouldLimitFileSize":false,"accept":".csv"},model:{value:(_vm.form.whitelist),callback:function ($$v) {_vm.$set(_vm.form, "whitelist", $$v)},expression:"form.whitelist"}}),(!_vm.form.whitelist)?_c('div',{staticClass:"flex-1 truncate ml-4"},[_c('a',{staticClass:"text-xl font-semibold",attrs:{"target":"_blank","download":"","href":_vm.projectDetail.presale_whitelist_url}},[_vm._v(_vm._s(_vm.projectDetail.whitelist_uploaded_name))])]):_vm._e()],1),_c('a',{staticClass:"text-right underline relative top-4 cursor-pointer text-xs w-max",attrs:{"href":_vm.WHITELIST_SAMPLE_URL,"target":"_blank","download":""}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_SAMPLE_DOWNLOAD_REF'))+" ")])]),_c('ErrorMessage',{attrs:{"id":"whitelist","error":errors[0]}})]}}],null,false,1759726611)})],1),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"max_purchasability"},slot:"max_purchasability"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min_value: 1,
                },"name":"PRESALE_MAX_PURCHASABILITY"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"error":errors[0],"placeholder":"0 ~"},model:{value:(_vm.form.presale_max_purchase),callback:function ($$v) {_vm.$set(_vm.form, "presale_max_purchase", $$v)},expression:"form.presale_max_purchase"}})]}}],null,false,1817089799)})],1)]),_c('ProjectViewSection',{staticClass:"!mb-0",attrs:{"title":"PROJECT_PRESALE_RESULT_SECTION_TITLE","columns":_vm.presaleStatistics,"dataSource":_vm.form}},[_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"sold"},slot:"sold"},[_vm._v(" - ")]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"revenue"},slot:"revenue"},[_vm._v(" - ")]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"available"},slot:"available"},[_vm._v(" - ")])]),_c('ProjectViewSection',{staticClass:"!mb-0",attrs:{"title":"PROJECT_PUBSALE_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.publicSaleSectionFiltered}},[_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"start"},slot:"start"},[_c('div',{staticClass:"flex items-center"},[_c('ValidationProvider',{attrs:{"rules":{ required: !_vm.form.is_publicsale_tba },"name":"PUBLIC_START_TIME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseDatepicker',{attrs:{"id":"public_start","disabled":_vm.form.is_publicsale_tba},model:{value:(_vm.form.publicsale_start_time),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_start_time", $$v)},expression:"form.publicsale_start_time"}}),(_vm.form.publicsale_start_time)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm._f("toJST")(_vm.form.publicsale_start_time))+" ")]):_vm._e(),_c('div',{staticClass:"ml-4"},[_c('a-checkbox',{attrs:{"checked":_vm.form.is_publicsale_tba},on:{"change":function($event){_vm.form.is_publicsale_tba = !_vm.form.is_publicsale_tba}}},[_vm._v(_vm._s(_vm.$t('TBA')))])],1)],1),_c('ErrorMessage',{attrs:{"id":"public_start","error":errors[0]}})]}}],null,false,2566587785)})],1)]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"end"},slot:"end"},[_c('div',{staticClass:"flex items-center"},[_c('ValidationProvider',{attrs:{"rules":{ required: !_vm.form.is_publicsale_tba },"name":"PUBLIC_END_TIME"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('BaseDatepicker',{attrs:{"id":"public_end","disabled":_vm.form.is_publicsale_tba},model:{value:(_vm.form.publicsale_end_time),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_end_time", $$v)},expression:"form.publicsale_end_time"}}),(_vm.form.publicsale_end_time)?_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(_vm._f("toJST")(_vm.form.publicsale_end_time))+" ")]):_vm._e()],1),_c('ErrorMessage',{attrs:{"id":"public_start","error":_vm.publicSaleTimeError ? _vm.$t(_vm.publicSaleTimeError) : errors[0]}})]}}],null,false,4197732013)})],1)]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"amount"},slot:"amount"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: !Number(_vm.form.presale_amount),
                  min_value: 0,
                  max_value: 30000,
                },"name":"AMOUNT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"placeholder":"0 ~ 30000","error":errors[0]},model:{value:(_vm.form.publicsale_amount),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_amount", $$v)},expression:"form.publicsale_amount"}})]}}],null,false,3314360414)})],1),(_vm.form.transfer_unsold_disabled)?_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"publicsale_target"},slot:"publicsale_target"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min_value: 1,
                  max_value: _vm.form.publicsale_amount,
                },"name":"PUBLIC_SALE_TARGET"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"error":errors[0]},model:{value:(_vm.form.publicsale_target),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_target", $$v)},expression:"form.publicsale_target"}})]}}],null,false,1490657579)})],1):_vm._e(),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"price"},slot:"price"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: !_vm.form.is_publicsale_price_tba,
                },"name":"PRICE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"flex items-center"},[_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":18,"placeholder":"0 ~","disabled":_vm.form.is_publicsale_price_tba,"error":errors[0]},model:{value:(_vm.form.publicsale_price),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_price", $$v)},expression:"form.publicsale_price"}}),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.symbol)+" ")]),_c('div',{staticClass:"ml-4"},[_c('a-checkbox',{attrs:{"checked":_vm.form.is_publicsale_price_tba},on:{"change":function($event){_vm.form.is_publicsale_price_tba = !_vm.form.is_publicsale_price_tba}}},[_vm._v(_vm._s(_vm.$t('TBA')))])],1)],1)]}}],null,false,2632425495)})],1),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"max_purchasability"},slot:"max_purchasability"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: true,
                  min_value: 1,
                },"name":"PUBLIC_MAX_PURCHASABILITY"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-80",attrs:{"type":"text","decimals":0,"placeholder":"0 ~","error":errors[0]},model:{value:(_vm.form.publicsale_max_purchase),callback:function ($$v) {_vm.$set(_vm.form, "publicsale_max_purchase", $$v)},expression:"form.publicsale_max_purchase"}})]}}],null,false,3945590881)})],1)]),_c('ProjectViewSection',{staticClass:"!mb-0",attrs:{"title":"PROJECT_PUBSALE_RESULT_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.publicSaleStatistics}},[_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"sold"},slot:"sold"},[_vm._v(" - ")]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"revenue"},slot:"revenue"},[_vm._v(" - ")]),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"available"},slot:"available"},[_vm._v(" - ")])]),_c('ProjectViewSection',{attrs:{"title":"PROJECT_FEE_SECTION_TITLE","dataSource":_vm.form,"columns":_vm.feeSection}},[_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"fee"},slot:"fee"},[_c('ValidationProvider',{attrs:{"name":"FEE","rules":{
                  required: true,
                  max_value: 100,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-32",attrs:{"type":"text","decimals":2,"inputClass":"ratio-input","placeholder":"2.0"},model:{value:(_vm.form.fee),callback:function ($$v) {_vm.$set(_vm.form, "fee", $$v)},expression:"form.fee"}}),_vm._v(" % "),_c('ErrorMessage',{attrs:{"id":"presale_end","error":errors[0]}})]}}],null,false,1723179089)})],1),_c('div',{staticClass:"text-xl font-semibold",attrs:{"slot":"dev_fee_rate"},slot:"dev_fee_rate"},[_c('ValidationProvider',{attrs:{"name":"PROJECT_REFERRAL_FEE_LABEL","rules":{
                  required: true,
                  max_value: 100,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('InputNumberCustom',{staticClass:"inline-block w-32",attrs:{"type":"text","decimals":2,"inputClass":"ratio-input","placeholder":"0.2"},model:{value:(_vm.form.dev_fee_rate),callback:function ($$v) {_vm.$set(_vm.form, "dev_fee_rate", $$v)},expression:"form.dev_fee_rate"}}),_vm._v(" % "),_c('ErrorMessage',{attrs:{"id":"presale_end","error":errors[0]}})]}}],null,false,2419339204)})],1)])],1),_c('div',{staticClass:"w-full text-center bg-section-bg py-8"},[_c('button',{staticClass:"bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_SAVE_BTN'))+" ")])])])])],1):_c('div',{staticClass:"mt-20 text-2xl font-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('PROJECT_INVALID_ROLE_NOTIFICATION'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }