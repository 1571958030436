<template>
  <div
    v-if="projectDetail.id"
    class="project-detail-page pb-8"
  >
    <button
      class="text-sm mb-8 cursor-pointer"
      @click="() => $router.push('/manage/projects')"
    >
      &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
    </button>
    <section class="text-3xl mb-12 font-semibold">{{ $t('PROJECT_SALE_INFO_PAGE_TITLE') }}</section>
    <a-button
      v-if="connectedChainId !== projectDetail.chain_id"
      class="flex items-center px-4 text-base py-2 rounded-lg border border-white w-max"
      @click="$root.$emit('switch-metamask-chain', projectDetail.chain_id)"
    >
      {{ $t('BUTTON_CONNECT_CHAIN', { chainName: CHAIN_INFO[projectDetail.chain_id].name }) }}
    </a-button>
    <section
      v-else
      class="project-info"
    >
      <div v-if="isMatchChainId">
        <ProjectViewSection
          title="PROJECT_GENERAL_SECTION_TITLE"
          :dataSource="projectDetail"
          :columns="generalSection"
        >
          <div slot="name">
            <span>
              {{ showName }}
            </span>
          </div>
          <div slot="detail">
            <div class="break-words whitespace-pre-line">
              <div v-html="$sanitize(showDescription)"></div>
            </div>
          </div>
          <div
            slot="chain_id"
            slot-scope="record"
          >
            <div target="_blank">
              {{ CHAIN_INFO[record.value].name }}
            </div>
          </div>
          <div
            slot="payment"
            slot-scope="record"
          >
            <div>
            {{ 
              record.value === 1
                ? $t('有効')
                : $t('無効')
             }}
            </div>
          </div>
          <div
            slot="image_uploaded_name"
            slot-scope="{ value, record }"
          >
            <a
              class="font-semibold"
              target="_blank"
              download
              :href="record.nft_images_url"
              >{{ value }}</a
            >
          </div>
          <div
            slot="json_uploaded_name"
            slot-scope="{ value, record }"
          >
            <a
              class="font-semibold"
              target="_blank"
              download
              :href="record.nft_json_url"
              >{{ value }}</a
            >
          </div>

          <div
            slot="website_url"
            slot-scope="record"
          >
            <a
              target="_blank"
              :href="record.value"
              class="text-primary font-semibold"
              >{{ record.value }}</a
            >
          </div>
          <div
            slot="twitter_url"
            slot-scope="record"
          >
            <a
              target="_blank"
              :href="record.value"
              class="text-primary font-semibold"
              >{{ record.value }}</a
            >
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          :title="
            projectDetail.is_revealed
              ? 'PROJECT_REVEAL_DATE_SECTION_TITLE'
              : 'PROJECT_REVEAL_SETTING_SECTION_TITLE'
          "
          :columns="revealSection"
        >
          <div
            v-if="projectDetail.status >= 3"
            slot="can_reveal"
            slot-scope="props"
            class="font-semibold"
          >
            <template v-if="props.value">
              <div v-if="!projectDetail.is_revealed">
                <button
                  class="bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl"
                  :disabled="loadingReveal || loadingStart"
                  @click="handleRevealProject"
                >
                  Reveal
                </button>
              </div>
              <div v-else>
                {{ projectDetail.revealed_at | toJST }}
              </div>
            </template>

            <div v-else>No</div>
          </div>
          <div
            v-else
            slot="can_reveal"
            class="font-semibold"
          >
            {{ props.record.can_reveal ? 'Yes' : 'No' }}
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROJECT_PRESALE_SECTION_TITLE"
          :columns="presaleSection"
        >
          <div
            slot="start"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toJST }}
          </div>
          <div
            slot="end"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toJST }}
          </div>
          <div
            slot="amount"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toCurrency }}
          </div>
          <div
            slot="price"
            slot-scope="record"
            class="font-semibold"
          >
            <div v-if="projectDetail.is_presale_price_tba">{{ $t('TBA') }}</div>
            <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
          </div>
          <div
            slot="whitelist"
            slot-scope="record"
          >
            <a
              class="font-semibold"
              target="_blank"
              download
              :href="record.value"
              >{{ record.value }}</a
            >
          </div>
          <div
            slot="max_purchase"
            slot-scope="record"
          >
            <a class="font-semibold">{{ record.value | toCurrency }}</a>
          </div>
          <div
            slot="transfer_unsold_disabled"
            slot-scope="record"
          >
            <a class="font-semibold">{{ record.value ? 'No' : 'Yes' }}</a>
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROJECT_PUBSALE_SECTION_TITLE"
          :columns="publicSaleSection"
        >
          <div
            slot="start"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toJST }}
          </div>
          <div
            slot="end"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toJST }}
          </div>
          <div
            slot="amount"
            slot-scope="record"
            class="font-semibold"
          >
            {{ record.value | toCurrency }}
          </div>
          <div
            slot="price"
            slot-scope="record"
            class="font-semibold"
          >
            <div v-if="projectDetail.is_publicsale_price_tba">{{ $t('TBA') }}</div>
            <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
          </div>
          <div
            slot="whitelist"
            slot-scope="record"
          >
            <a class="font-semibold">{{ record.value }}</a>
          </div>
        </ProjectViewSection>

        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROJECT_FEE_SECTION_TITLE"
          :columns="feeSection"
        >
          <div
            slot-scope="record"
            slot="fee"
            class="font-semibold"
          >
            {{ Number(record.value) }}&nbsp;%
          </div>
          <div
            slot-scope="record"
            slot="dev_fee_rate"
            class="font-semibold"
          >
            {{ Number(record.value) }}&nbsp;%
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROJECT_DEPLOYED_CONTRACT_SECTION_TITLE"
          :columns="contractSection"
        >
          <div
            slot="contract_address"
            class="font-medium"
          >
            <div v-if="projectDetail.contract_address">
              {{ projectDetail.contract_address }}
            </div>
            <div v-else>-</div>
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROJECT_CLAIM_UNSOLD_SECTION_TITLE"
          :columns="claimSection"
          v-if="projectDetail.status_code === 'FINISHED' && isProjectOwner"
        >
          <div
            slot="unsoldAmount"
            class="font-medium"
          >
            <span>{{ remainAmount }}</span>
          </div>
          <div
            slot="claim"
            class="font-medium"
          >
            <ValidationObserver ref="observer">
              <form @submit.prevent="onClaimUnsold">
                <ValidationProvider
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: maxToClaim,
                  }"
                  v-slot="{ errors }"
                  name="CLAIM_AMOUNT"
                >
                  <InputNumberCustom
                    type="text"
                    v-model="amountToClaim"
                    :decimals="0"
                    class="block"
                    placeholder="1 ~ 50"
                    :error="errors[0]"
                  ></InputNumberCustom>
                  <button
                    class="bg-primary text-white hover:opacity-60 text-xl font-semibold mt-2 py-1 relative -top-1 w-40 rounded-xl"
                    type="submit"
                    :disabled="claiming"
                    v-if="isMatchChainId"
                  >
                    {{ $t('PROJECT_CLAIM_UNSOLD_BTN') }}
                  </button>
                </ValidationProvider>
              </form>
            </ValidationObserver>
          </div>
        </ProjectViewSection>
        <ProjectViewSection
          :dataSource="projectDetail"
          title="PROCEEDS_SECTION_TITLE"
          :columns="proceedsSection"
        >
          <div
            slot="pending_fee"
            class="text-xl font-semibold"
          >
            <div>
              <span
                ><CryptoIcon
                  class="mr-2"
                  :symbol="symbol"
                ></CryptoIcon
                >{{ pendingFee }}&nbsp;{{ symbol }}</span
              >
              <span
                class="xl:ml-24 lg:ml-16 ml-8"
                v-if="pendingFee > 0"
              >
                <button
                  @click="withdrawFee"
                  class="text-white bg-primary py-2 px-8 text-2xl mt-4 rounded-2xl font-semibold"
                >
                  {{ $t('WITHDRAW_BTN') }}
                </button>
              </span>
            </div>
          </div>
        </ProjectViewSection>
        <div class="w-full text-center py-8 bg-section-bg">
          <button
            v-if="connectedChainId === projectDetail.chain_id"
            class="bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl"
            :disabled="loadingReveal || loadingStart"
            @click="handleStartProject"
          >
            {{ $t('PROJECT_START_BTN') }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import UtilNotify from '@/utils/notify.util';
import { revealProject, getPendingFee, claimFee } from '@/services/blockchain/project';
import { startProject } from '@/services/projects';
import { compareAddress } from '@/utils/address.util';
import InputNumberCustom from '@/components/InputNumberCustom.vue';
import { claimUnsold, getRemainAmount } from '@/services/blockchain/project';
import CryptoIcon from '@/components/common/CryptoIcon.vue';

const generalSection = [
  {
    key: 'symbol',
    title: 'PROJECT_SYMBOL_LABEL',
  },
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL',
    scopedSlots: {
      value: 'name',
    },
  },
  {
    key: 'formattedDescription',
    title: 'PROJECT_DESCRIPTION_LABEL',
    scopedSlots: {
      value: 'detail',
    },
  },
  {
    key: 'chain_id',
    title: 'PROJECT_CHAIN_LABEL',
    scopedSlots: {
      value: 'chain_id',
    },
  },
  {
    key: 'payment',
    title: 'BUTTON_PIMENT_BUY',
    scopedSlots: { value: 'payment' },
  },
  {
    key: 'image_uploaded_name',
    title: 'PROJECT_IMG_UPLOAD_LABEL',
    scopedSlots: {
      value: 'image_uploaded_name',
    },
  },
  {
    key: 'json_uploaded_name',
    title: 'PROJECT_CSV_UPLOAD_LABEL',
    scopedSlots: {
      value: 'json_uploaded_name',
    },
  },
  {
    key: 'website_url',
    title: 'PROJECT_WEBSITE_LABEL',
    scopedSlots: {
      value: 'website_url',
    },
  },
  {
    key: 'twitter_url',
    title: 'PROJECT_TWITTER_LABEL',
    scopedSlots: { value: 'twitter_url' },
  },
];

const presaleSection = [
  {
    key: 'presale_start_time',
    title: 'PROJECT_PRESALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'presale_end_time',
    title: 'PROJECT_PRESALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'presale_amount',
    title: 'PROJECT_PRESALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'presale_price',
    title: 'PROJECT_PRESALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'whitelist_uploaded_name',
    title: 'PROJECT_PRESALE_WHITELIST_LABEL',
    scopedSlots: { value: 'whitelist' },
  },
  {
    key: 'presale_max_purchase',
    title: 'PROJECT_PRESALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchase' },
  },
  {
    key: 'transfer_unsold_disabled',
    title: 'PROEJCT_PRESALE_TRANSFER_UNSOLD_LABEL',
    scopedSlots: { value: 'transfer_unsold_disabled' },
  },
  {
    key: 'transfer_unsold_disabled',
    title: 'Transfer unsold',
    scopedSlots: { value: 'transfer_unsold_disabled' },
  },
];

const publicSaleSection = [
  {
    key: 'publicsale_start_time',
    title: 'PROJECT_PUBSALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'publicsale_end_time',
    title: 'PROJECT_PUBSALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'publicsale_amount',
    title: 'PROJECT_PUBSALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'publicsale_price',
    title: 'PROJECT_PUBSALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'publicsale_max_purchase',
    title: 'PROJECT_PUBSALE_MAX_PURCHASE_LABEL',
  },
];

const feeSection = [
  {
    key: 'fee',
    title: 'PROJECT_FEE_LABEL',
    scopedSlots: { value: 'fee' },
  },
  {
    key: 'dev_fee_rate',
    title: 'PROJECT_REFERRAL_FEE_LABEL',
    scopedSlots: { value: 'dev_fee_rate' },
  },
];
const contractSection = [
  {
    key: 'contract_address',
    title: '',
    scopedSlots: { title: 'contract_address', value: 'contract' },
  },
];

const claimSection = [
  {
    key: '',
    title: 'PROJECT_REMAINING_AMOUNT_LABEL',
    scopedSlots: { value: 'unsoldAmount' },
  },
  {
    key: '',
    title: 'PROJECT_CLAIM_UNSOLD_AMOUNT_LABEL',
    scopedSlots: { value: 'claim' },
  },
];
const proceedsSection = [
  {
    key: 'pending_fee',
    title: '',
    scopedSlots: { value: 'pending_fee' },
  },
];

export default {
  name: 'ProjectDetailPage',
  props: {},
  components: {
    ProjectViewSection,
    InputNumberCustom,
    CryptoIcon,
  },
  data() {
    return {
      generalSection,
      presaleSection,
      publicSaleSection,
      feeSection,
      contractSection,
      claimSection,
      proceedsSection,
      CHAIN_INFO,
      loadingReveal: false,
      loadingStart: false,
      amountToClaim: 1,
      claiming: false,
      remainAmount: 0,
      pendingFee: 0,
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
    }),
    ...mapState({
      locale: (state) => state?.setting.locale,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        this.setToConnectChain(data.chain_id);
        return data;
      }
      return {};
    },
    isMatchChainId() {
      return this.connectedChainId === this.projectDetail.chain_id;
    },
    symbol() {
      if (!this.projectDetail) return 'ETH';
      return this.projectDetail.token_address
        ? TOKEN_SYMBOLS[this.projectDetail.chain_id][this.projectDetail.token_address]
        : CHAIN_INFO[this.projectDetail.chain_id].nativeCurrency.symbol;
    },
    revealSection() {
      const revealSection = [
        {
          key: 'can_reveal',
          title: this.projectDetail.is_revealed
            ? 'PROJECT_REVEALE_DATE_LABEL'
            : 'PROJECT_REVEAL_LABEL',
          scopedSlots: { value: 'can_reveal' },
        },
      ];
      return revealSection;
    },
    ownerAddress() {
      return this.projectDetail.owner_address;
    },
    isProjectOwner() {
      return this.compareAddress(this.ownerAddress, this.account);
    },
    maxToClaim() {
      return Math.min(50, this.remainAmount);
    },
    showName() {
      return this.locale === 'ja' && this.projectDetail.name_ja
        ? this.projectDetail.name_ja
        : this.projectDetail.name;
    },
    showDescription() {
      return this.locale === 'ja' && this.projectDetail.description_ja
        ? this.projectDetail.description_ja
        : this.projectDetail.description;
    },
  },
  watch: {
    async isMatchChainId(val) {
      if (val) {
        await this.getRemainAmount();
      }
    },
    async isProjectOwner(val) {
      if (val) {
        await this.getRemainAmount();
      }
    },
    async connectedChainId() {
      await this.getPendingFee();
    },
    async account() {
      await this.getPendingFee();
    },
  },
  async mounted() {
    await this.fetchProjectDetailById();
    await this.getRemainAmount();
    await this.getPendingFee();
  },
  methods: {
    ...mapMutations({
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    async fetchProjectDetailById() {
      try {
        const { id } = this.$route.params;
        await this.fetchProjectDetail(id);
      } catch (error) {
        console.debug({ error });
        UtilNotify.error(this.$notify, error);
        this.$router.push('/manage/projects');
      }
    },
    claimUnsold,
    compareAddress,
    async getRemainAmount() {
      if (!this?.$connector?.provider) {
        this.remainAmount = 0;
      } else {
        this.remainAmount = await getRemainAmount({
          provider: this.$connector.provider,
          contractAddress: this.projectDetail.contract_address,
        });
      }
      return this.remainAmount;
    },
    async handleRevealProject() {
      try {
        this.loadingReveal = true;
        if (this.projectDetail.status_code !== 'ERROR' && this.projectDetail.status >= 2) {
          const provider = this.$connector.provider;
          const { contract_project_id: projectId } = this.projectDetail;
          await revealProject({
            provider,
            account: this.account,
            chainId: this.connectedChainId,
            projectId,
            baseTokenURI: this.projectDetail.reveal_base_token_uri,
          });
          UtilNotify.success(this.$notify, { message: 'NOTIFICATION_SUCCESS_TRANSACTION' });
          this.$router.push('/manage/projects');
        } else {
          UtilNotify.error(this.$notify, {
            message: 'PROJECT_NOT_READY_REVEAL_NOTIFICATION',
          });
        }
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      } finally {
        this.loadingReveal = false;
      }
    },
    async handleStartProject() {
      try {
        this.loadingStart = true;
        if (this.projectDetail.status_code === 'CREATED') {
          const { id: projectId } = this.projectDetail;
          await startProject(projectId);
          UtilNotify.success(this.$notify, { message: 'PROJECT_STARTED_NOTIFICATION' });
          this.$router.push('/manage/projects');
        } else {
          UtilNotify.error(this.$notify, {
            message: 'PROEJCT_NOT_READY_START_NOTIFICATION',
          });
        }
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      } finally {
        this.loadingStart = false;
      }
    },
    async onClaimUnsold() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          return;
        }
        if (this.claiming) return;
        this.claiming = true;
        const provider = this.$connector.provider;
        await this.claimUnsold({
          provider,
          account: this.account,
          projectId: this.projectDetail.contract_project_id,
          amount: this.amountToClaim,
          chainId: this.projectDetail.chain_id,
        });
        await this.getRemainAmount();
        UtilNotify.success(
          this.$notify,
          { message: 'NOTIFICATION_SUCCESS_TRANSACTION' },
          this.$modal
        );
        this.claiming = false;
      } catch (error) {
        this.claiming = false;
        UtilNotify.error(this.$notify, error, this.$modal);
        console.error(error);
      }
    },
    async getPendingFee() {
      const provider = this.$connector?.provider;
      const { contract_project_id: projectId, chain_id: chainId } = this.projectDetail;
      if (!provider || !this.account || this.connectedChainId !== chainId || !projectId) {
        this.pendingFee = 0;
      } else {
        this.pendingFee = await getPendingFee({
          provider,
          account: this.account,
          chainId: this.connectedChainId,
          projectId,
        });
      }
    },
    async withdrawFee() {
      try {
        const provider = this.$connector.provider;
        await claimFee({
          provider,
          account: this.account,
          projectId: this.projectDetail.contract_project_id,
          chainId: this.projectDetail.chain_id,
        });
        await this.getPendingFee();
        UtilNotify.success(
          this.$notify,
          { message: 'NOTIFICATION_SUCCESS_TRANSACTION' },
          this.$modal
        );
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      }
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
::v-deep .input-number-custom input {
  @apply px-2 rounded-none;
  @apply bg-white text-black text-lg;
  @apply h-8 w-40 relative -top-1;
  @apply disabled:cursor-not-allowed;
}

::v-deep .ant-input {
  @apply border-none rounded-sm text-black text-lg;
}
</style>
