<template>
  <div
    v-if="projectDetail"
    class="basic-info-page pb-8"
  >
    <button
      class="text-sm mb-8 cursor-pointer"
      @click="() => $router.push('/manage/projects')"
    >
      &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
    </button>
    <section class="text-3xl mb-12 font-semibold">
      {{ $t('PROJECT_BASIC_INFO_PAGE_TITLE') }}
    </section>
    <section class="project-info">
      <ProjectViewSection
        title="PROJECT_GENERAL_SECTION_TITLE"
        :dataSource="projectDetail"
        :columns="generalSection"
      >
        <div slot="name">
          <span>
            {{ showName }}
          </span>
        </div>
        <div slot="detail">
          <div class="break-words whitespace-pre-line">
            <div v-html="$sanitize(showDescription)"></div>
          </div>
        </div>
        <div
          slot="image_uploaded_name"
          slot-scope="{ value, record }"
        >
          <a
            class="font-semibold"
            target="_blank"
            download
            :href="record.nft_images_url"
            >{{ value }}</a
          >
        </div>

        <div
          slot="json_uploaded_name"
          slot-scope="{ value, record }"
        >
          <a
            class="font-semibold"
            target="_blank"
            download
            :href="record.nft_json_url"
            >{{ value }}</a
          >
        </div>
        <div
          slot="reveal"
          slot-scope="{ record }"
          class="flex items-center"
        >
          <div>
            {{ record.can_reveal ? 'Yes' : 'No' }}
          </div>
          <div
            v-if="record.can_reveal"
            class="ml-4 md:ml-6"
          >
            <a
              class="font-semibold"
              target="_blank"
              download
              :href="record.reveal_thumbnail_url"
            >
              {{ record.reveal_uploaded_name }}
            </a>
          </div>
        </div>
        <div
          slot="chain_id"
          slot-scope="record"
        >
          <div target="_blank">
            {{ CHAIN_INFO[record.value].name }}
          </div>
        </div>
        <div
          slot="payment"
          slot-scope="record"
        >
          <div>
          {{ 
            record.value === 1
              ? $t('有効')
              : $t('無効')
          }}
          </div>
        </div>
        <div
          slot="website_url"
          slot-scope="record"
        >
          <a
            target="_blank"
            :href="record.value"
            class="text-primary font-semibold"
            >{{ record.value }}</a
          >
        </div>
        <div
          slot="twitter_url"
          slot-scope="record"
        >
          <a
            target="_blank"
            :href="record.value"
            class="text-primary font-semibold"
            >{{ record.value }}</a
          >
        </div>
        <div
          slot="address"
          slot-scope="{ value }"
        >
          <div class="text-18px font-semibold">
            {{ value }}
          </div>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PRESALE_SECTION_TITLE"
        :columns="presaleSection"
      >
        <div
          slot="start"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_presale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="end"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_presale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="amount"
          slot-scope="record"
          class="font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="price"
          slot-scope="record"
          class="font-semibold"
        >
          <div v-if="projectDetail.is_presale_price_tba">{{ $t('TBA') }}</div>
          <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
        </div>
        <div
          slot="whitelist"
          slot-scope="record"
        >
          <a
            class="font-semibold"
            target="_blank"
            download
            :href="record.value"
            >{{ record.value }}</a
          >
        </div>
        <div
          slot="max_purchase"
          slot-scope="record"
        >
          <a class="font-semibold">{{ record.value | toCurrency }}</a>
        </div>
        <div
          slot="transfer_unsold_disabled"
          slot-scope="record"
        >
          <a class="font-semibold">{{ record.value ? 'No' : 'Yes' }}</a>
        </div>
      </ProjectViewSection>
      <ProjectViewSection
        :dataSource="projectDetail"
        title="PROJECT_PUBSALE_SECTION_TITLE"
        :columns="publicSaleSection"
      >
        <div
          slot="start"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_publicsale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="end"
          slot-scope="{ value, record }"
          class="text-xl font-semibold"
        >
          <template v-if="value"> {{ value | toJST }}</template>
          <template v-else-if="record.is_publicsale_tba">{{ $t('TBA') }}</template>
        </div>
        <div
          slot="amount"
          slot-scope="record"
          class="font-semibold"
        >
          {{ record.value | toCurrency }}
        </div>
        <div
          slot="price"
          slot-scope="record"
          class="font-semibold"
        >
          <div v-if="projectDetail.is_publicsale_price_tba">{{ $t('TBA') }}</div>
          <div v-else>{{ record.value | toCurrency }}&nbsp;{{ symbol }}</div>
        </div>
        <div
          slot="whitelist"
          slot-scope="record"
        >
          <a class="font-semibold">{{ record.value }}</a>
        </div>
      </ProjectViewSection>
    </section>
    <div class="w-full text-center py-8 bg-section-bg">
      <template v-if="connectedChainId === projectDetail.chain_id">
        <button
          v-if="
            !projectDetail.is_publicsale_tba &&
            !projectDetail.is_presale_tba &&
            !projectDetail.is_publicsale_price_tba &&
            !projectDetail.is_presale_price_tba
          "
          class="bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl"
          type="submit"
          :disabled="loading"
          @click="handleButtonSaveClick"
        >
          {{ $t('PROJECT_BASIC_INFO_SAVE_BTN') }}
        </button></template
      >

      <button
        v-else
        class="bg-primary hover:opacity-60 text-white text-2xl font-semibold py-2 px-12 rounded-xl"
        @click="() => $root.$emit('switch-metamask-chain', projectDetail.chain_id)"
      >
        {{ $t('BUTTON_CONNECT_CHAIN', { chainName: CHAIN_INFO[projectDetail.chain_id].name }) }}
      </button>
    </div>
    <div
      v-if="
        account &&
        (projectDetail.status < 2 || projectDetail.status_code === 'ERROR') &&
        userRole === 'admin'
      "
      class="bg-section-bg flex items-center justify-center pb-20 pt-10"
    >
      <button
        class="flex items-center px-4 md:px-10 text-2xl py-2 rounded-lg bg-primary"
        :disabled="loading"
        @click="deleteAProject"
      >
        <DeleteIcon class="text-white mr-2 w-8 h-8" />
        {{ $t('PROJECT_BASIC_INFO_DELETE_BTN') }}
      </button>
    </div>
  </div>
</template>

<script>
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { CHAIN_INFO, TOKEN_SYMBOLS } from '@/constants/chains.constant';
import UtilNotify from '@/utils/notify.util';
import { saveProject } from '@/services/blockchain/project';
import { deleteProject, getSignatureForCreateContractProject } from '@/services/projects';
import DeleteIcon from '../components/icons/box_delete.icon.vue';

import dayjs from 'dayjs';
import { shift, BigNumber } from '@/utils/bignumber.util';
import { ZERO_ADDRESS } from '../env';
import { getDecimals } from '@/utils/erc20.util';

const generalSection = [
  {
    key: 'symbol',
    title: 'PROJECT_SYMBOL_LABEL',
  },
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL',
    scopedSlots: {
      value: 'name',
    },
  },
  {
    key: 'formattedDescription',
    title: 'PROJECT_DESCRIPTION_LABEL',
    scopedSlots: {
      value: 'detail',
    },
  },
  {
    key: 'chain_id',
    title: 'PROJECT_CHAIN_LABEL',
    scopedSlots: {
      value: 'chain_id',
    },
  },
  {
    key: 'payment',
    title: 'BUTTON_PIMENT_BUY',
    scopedSlots: { value: 'payment' },
  },
  {
    key: 'image_uploaded_name',
    title: 'PROJECT_IMG_UPLOAD_LABEL',
    scopedSlots: {
      value: 'image_uploaded_name',
    },
  },

  {
    key: 'json_uploaded_name',
    title: 'PROJECT_CSV_UPLOAD_LABEL',
    scopedSlots: {
      value: 'json_uploaded_name',
    },
  },
  {
    key: 'is_reveal',
    title: 'PROJECT_REVEAL_SETTING_SECTION_TITLE',
    scopedSlots: { value: 'reveal' },
  },
  {
    key: 'website_url',
    title: 'PROJECT_WEBSITE_LABEL',
    scopedSlots: {
      value: 'website_url',
    },
  },
  {
    key: 'twitter_url',
    title: 'PROJECT_TWITTER_LABEL',
    scopedSlots: { value: 'twitter_url' },
  },
  {
    key: 'owner_address',
    title: 'PROJECT_OWNER_ADDRESS_LABEL',
  },
];

const presaleSection = [
  {
    key: 'presale_start_time',
    title: 'PROJECT_PRESALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'presale_end_time',
    title: 'PROJECT_PRESALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'presale_amount',
    title: 'PROJECT_PRESALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'presale_price',
    title: 'PROJECT_PRESALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'whitelist_uploaded_name',
    title: 'PROJECT_PRESALE_WHITELIST_LABEL',
    scopedSlots: { value: 'whitelist' },
  },
  {
    key: 'presale_max_purchase',
    title: 'PROJECT_PRESALE_MAX_PURCHASE_LABEL',
    scopedSlots: { value: 'max_purchase' },
  },
  {
    key: 'transfer_unsold_disabled',
    title: 'PROEJCT_PRESALE_TRANSFER_UNSOLD_LABEL',
    scopedSlots: { value: 'transfer_unsold_disabled' },
  },
  {
    key: 'transfer_unsold_disabled',
    title: 'Transfer unsold',
    scopedSlots: { value: 'transfer_unsold_disabled' },
  },
];

const publicSaleSection = [
  {
    key: 'publicsale_start_time',
    title: 'PROJECT_PUBSALE_START_LABEL',
    scopedSlots: { value: 'start' },
  },
  {
    key: 'publicsale_end_time',
    title: 'PROJECT_PUBSALE_END_LABEL',
    scopedSlots: { value: 'end' },
  },
  {
    key: 'publicsale_amount',
    title: 'PROJECT_PUBSALE_AMOUNT_LABEL',
    scopedSlots: { value: 'amount' },
  },
  {
    key: 'publicsale_price',
    title: 'PROJECT_PUBSALE_PRICE_LABEL',
    scopedSlots: { value: 'price' },
  },
  {
    key: 'publicsale_max_purchase',
    title: 'PROJECT_PUBSALE_MAX_PURCHASE_LABEL',
  },
];

export default {
  name: 'BasicInfoPage',
  props: {},
  components: {
    ProjectViewSection,
    DeleteIcon,
  },
  data() {
    return {
      generalSection,
      presaleSection,
      publicSaleSection,
      data: {},
      form: {
        is_reveal: false,
      },
      loading: false,
      CHAIN_INFO,
    };
  },
  computed: {
    ...mapGetters({
      account: 'wallet/account',
      userRole: 'wallet/loggedUserRole',
    }),
    ...mapState({
      locale: (state) => state?.setting.locale,
      projectDetailMap: (state) => state.projects.projectDetailMap,
      connectedChainId: (state) => state.wallet?.provider?.chainId || -1,
    }),
    projectDetail() {
      const { id } = this.$route.params;
      console.debug({ id, projectDetailMap: this.projectDetailMap });
      if (id) {
        const data = this.projectDetailMap[id];
        console.debug({ data });
        this.setToConnectChain(data.chain_id);
        return data;
      }
      return {};
    },
    symbol() {
      if (!this.projectDetail) return 'ETH';
      return this.projectDetail.token_address
        ? TOKEN_SYMBOLS[this.projectDetail.chain_id][this.projectDetail.token_address]
        : CHAIN_INFO[this.projectDetail.chain_id].nativeCurrency.symbol;
    },
    showName() {
      return this.locale === 'ja' && this.projectDetail.name_ja
        ? this.projectDetail.name_ja
        : this.projectDetail.name;
    },
    showDescription() {
      return this.locale === 'ja' && this.projectDetail.description_ja
        ? this.projectDetail.description_ja
        : this.projectDetail.description;
    },
  },
  async mounted() {
    await this.fetchProjectDetailById();
  },
  methods: {
    ...mapMutations({
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    ...mapActions({
      fetchProjectDetail: 'projects/fetchProjectDetail',
    }),
    async fetchProjectDetailById() {
      try {
        const { id } = this.$route.params;
        await this.fetchProjectDetail(id);
      } catch (error) {
        console.debug({ error });
        UtilNotify.error(this.$notify, error);
        this.$router.push('/manage/projects');
      }
    },
    async handleButtonSaveClick() {
      try {
        this.loading = true;
        const { status_code } = this.projectDetail;
        if (status_code !== 'READY') {
          UtilNotify.error(this.$notify, {
            message: 'PROJECT_NOT_READY_SAVE_NOTIFICATION',
          });
        } else {
          const provider = this.$connector.provider;
          const {
            name,
            id,
            fee,
            dev_fee_rate,
            has_whitelist,
            reveal_base_token_uri,
            unreveal_base_token_uri,
            presale_max_purchase,
            presale_price,
            presale_amount,
            publicsale_amount,
            publicsale_max_purchase,
            publicsale_price,
            presale_start_time,
            presale_end_time,
            publicsale_start_time,
            publicsale_end_time,
            transfer_unsold_disabled,
            symbol,
            token_address,
          } = this.projectDetail;
          const res = await getSignatureForCreateContractProject(this.projectDetail.id);
          let decimals = 18;
          if (token_address && token_address !== ZERO_ADDRESS) {
            decimals = await getDecimals(provider, token_address);
          }
          console.debug({ res });
          const signature = res.signature;
          const payload = {
            provider,
            account: this.account,
            chainId: this.connectedChainId,
            backendId: id,
            projectName: name,
            baseTokenURI: this.projectDetail.can_reveal
              ? unreveal_base_token_uri
              : reveal_base_token_uri,
            saleData: [
              dayjs(presale_start_time).unix(),
              dayjs(presale_end_time).unix(),
              shift(presale_price, decimals),
              presale_amount,
              presale_max_purchase,
              dayjs(publicsale_start_time).unix(),
              dayjs(publicsale_end_time).unix(),
              shift(publicsale_price, decimals),
              publicsale_amount,
              publicsale_max_purchase,
              new BigNumber(fee).times(100).dp(0).toString(),
              new BigNumber(dev_fee_rate).times(100).dp(0).toString(),
            ],
            hasWhitelist: has_whitelist,
            canReveal: this.projectDetail.can_reveal,
            transferUnsoldDisabled: transfer_unsold_disabled,
            signature,
            symbol,
            paymentToken: token_address,
          };
          console.debug({ payload });
          await saveProject(payload);
          UtilNotify.success(this.$notify, { message: 'NOTIFICATION_SUCCESS_TRANSACTION' });
          this.$router.push('/manage/projects');
        }
      } catch (error) {
        UtilNotify.error(this.$notify, error, this.$modal);
      } finally {
        this.loading = false;
      }
    },
    async deleteAProject() {
      try {
        this.loading = true;
        await deleteProject(this.projectDetail.id);
        UtilNotify.success(this.$notify, { message: 'PROJECT_DELETE_SUCCESS_NOTIFICATION' });
        this.$router.push('/manage/projects');
      } catch (error) {
        UtilNotify.error(this.$notify, error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
