<template>
  <div>
    <a-dropdown
      v-if="account"
      :trigger="['click']"
      overlay-class-name="connected-wallet"
      placement="bottomRight"
    >
      <a-menu
        slot="overlay"
        @click="handleMenuClick"
      >
        <a-menu-item key="disconnect">
          <div class="flex items-center justify-between">
            <span class="text-red-500">{{ $t('DISCONNECT') }}</span>
          </div>
        </a-menu-item>
      </a-menu>
      <button
        class="connectbutton rounded-full flex items-center justify-center border border-primary text-white px-4"
      >
        <span>{{ account | truncate }}</span>
      </button>
    </a-dropdown>

    <button
      v-else
      class="connectbutton rounded-full flex items-center justify-center border border-primary text-white px-4"
      @click="setModalRequestState(true)"
    >
      {{ $t('CONNECT_WALLET') }}
    </button>
  </div>
</template>

<script>
import { CHAIN_ID } from '@/env';
import { mapState, mapMutations, mapActions } from 'vuex';
import shareListenerMixin from '@/mixins/share-listener.mixin';

export default {
  name: 'App',
  mixins: [shareListenerMixin],

  data() {
    return {
      CHAIN_ID,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.wallet?.provider?.address || '',
      toConnectChainId: (state) => state.wallet.toConnectChainId,
    }),
  },
  created() {
    if (this.account) {
      this.updateNativeBalance();
    }
  },
  watch: {
    account(val) {
      if (val) {
        this.updateNativeBalance();
      }
    },
  },
  methods: {
    ...mapActions({
      updateNativeBalance: 'wallet/updateBalance',
    }),
    ...mapMutations({
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
    }),
    async handleMenuClick(ele) {
      const { key } = ele;
      if (key === 'disconnect') {
        await this.handleDisconnect();
      }
    },
  },
};
</script>

<style>
.connectbutton {
  height: 40px;
  width: auto;
  font-size: auto;
  background-color: #FF0085;
}
  @media (max-width: 768px) {
  .connectbutton {
    font-size: 10px;
    white-space: nowrap; /* テキストが折り返さないようにする */
    font-weight: bold; /* 太字にする */
  }
}
</style>